import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'

import {
  OidcCallbackBaseMessage,
  AuthOperation,
  OidcCallbackSuccessMessage,
  RedirectErrorCode,
} from '../../_api/signing-be/generated'
import { BatchDocument } from '../../contexts/batch'
import { useSession } from '../../contexts/session'
import { Size, useWindowSize, useLocale } from '../../hooks'
import { LanguageSelector, Page } from '../../components'
import { AvailableLocale } from '../../constants'
import { OneSignError } from '../errors/OneSignError'

import { Iframe } from './OneIDOperation.styled'

interface OneIdOperationPropsBase<T extends AuthOperation> {
  operation: T
  onComplete: (data: OidcCallbackSuccessMessage) => Promise<void>
}

interface SignOperation extends OneIdOperationPropsBase<typeof AuthOperation.Sign> {
  documents: BatchDocument[]
}

enum MessageType {
  ContainerProperties = 'container-properties',
}

interface Message {
  type: MessageType.ContainerProperties
  size: Size
  locale: AvailableLocale
}

export const OneIDOperation: React.FC<
  SignOperation | OneIdOperationPropsBase<typeof AuthOperation.Login | typeof AuthOperation.IssueCertificate>
> = (props) => {
  const { onComplete, operation } = props
  const locale = useLocale()
  const { wysiwysApi, session } = useSession()
  const size = useWindowSize()
  const iframeEl = useRef<null | HTMLIFrameElement>(null)

  const [error, setError] = useState<{ error: OneSignError }>()

  const fetchAuthURLMutation = useMutation(
    async () => {
      const response = await wysiwysApi.getNewInteractionURL({
        getNewInteractionUrlRequest:
          operation === AuthOperation.Sign
            ? {
                operation,
                hashes: props.documents.map((document) => ({ documentId: document.id, hash: document.hash })),
              }
            : { operation },
      })
      return response.data.url
    },
    { onSuccess: () => setError(undefined) },
  )

  const postMessage = useCallback(
    (message: Message) => {
      if (iframeEl.current && iframeEl.current.contentWindow && fetchAuthURLMutation.isSuccess) {
        iframeEl.current.contentWindow.postMessage(message, new URL(fetchAuthURLMutation.data).origin)
      }
    },
    [iframeEl, fetchAuthURLMutation],
  )

  const postContainerProperties = useCallback(() => {
    if (locale) {
      postMessage({ type: MessageType.ContainerProperties, size, locale })
    }
  }, [postMessage, size, locale])

  useEffect(() => {
    postContainerProperties()
  }, [postContainerProperties])

  const onMessageHandler = useCallback(
    async ({ data }: MessageEvent<OidcCallbackBaseMessage>) => {
      if (data.source === 'OneID') {
        switch (data.status) {
          case 'SUCCESS':
            await onComplete(data as OidcCallbackSuccessMessage).catch((error: any) => {
              setError({ error: error.response?.data ?? error })
            })
            return
          case 'ERROR':
            setError({ error: RedirectErrorCode.ConsentRejected })
            return
        }
      }
    },
    [onComplete],
  )

  useEffect(() => {
    if (fetchAuthURLMutation.isIdle) fetchAuthURLMutation.mutateAsync()
  }, [fetchAuthURLMutation])

  useEffect(() => {
    window.addEventListener('message', onMessageHandler)

    return () => {
      window.removeEventListener('message', onMessageHandler)
    }
  }, [onMessageHandler])

  if (fetchAuthURLMutation.isError) {
    return <OneSignError error={fetchAuthURLMutation.error} retry={fetchAuthURLMutation.mutateAsync} exit={session} />
  }

  if (error) {
    return <OneSignError error={error.error} retry={fetchAuthURLMutation.mutateAsync} exit={session} />
  }

  return fetchAuthURLMutation.data ? (
    <>
      <Iframe src={fetchAuthURLMutation.data} ref={iframeEl} onLoad={postContainerProperties} />
      <LanguageSelector iframe />
    </>
  ) : (
    <Page isLoading />
  )
}
