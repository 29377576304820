import React, { ChangeEventHandler, useState } from 'react'

import { Button, Modal } from '../../components'
import { ButtonVariant } from '../../components/Button.styled'

import { Label, Textarea } from './ConfirmationModal.styled'

export const DenySigningConfirmationModal = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: (reason: string) => void
  onCancel: () => void
}) => {
  const [reason, setReason] = useState<string>('')
  const handleConfirm = () => {
    onConfirm(reason)
  }

  const handleReasonChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setReason(e.target.value)
  }

  return (
    <Modal
      title="Are you sure you want to deny signing?"
      buttons={
        <>
          <Button variant={ButtonVariant.Outline} onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={handleConfirm}>Yes</Button>
        </>
      }
    >
      <Label htmlFor="textarea-whats-wrong">
        <strong>What’s wrong?</strong> (optional)
      </Label>
      <Textarea
        id="textarea-whats-wrong"
        placeholder="Please describe the problem...."
        rows={4}
        onChange={handleReasonChange}
        value={reason}
      />
    </Modal>
  )
}
