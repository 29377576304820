import { useLingui } from '@lingui/react'

import { AvailableLocale } from '../constants'

export const useLocale = () => {
  const {
    i18n: { locale: selectedLocale },
  } = useLingui()

  return Object.values(AvailableLocale).find((availableLocale) => availableLocale === selectedLocale)
}
