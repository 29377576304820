const BENEFIT_PROXY_PREFIX = '/benefit-proxy'

export const config = {
  signing: {
    apiUrl: window.location.origin,
  },
  benefit: {
    proxyPrefix: BENEFIT_PROXY_PREFIX,
    apiUrl: window.location.origin + BENEFIT_PROXY_PREFIX,
  },
  datadog: {
    applicationId: window.ENV_DATADOG_APPLICATION_ID,
    clientToken: window.ENV_DATADOG_CLIENT_TOKEN,
    service: window.ENV_DATADOG_SERVICE,
    version: window.ENV_DATADOG_VERSION,
    site: window.ENV_DATADOG_SITE,
    env: window.ENV_DATADOG_ENV,
  },
}
