import { styled } from '../../../styles/stitches.config'

export const StyledZoomControl = styled('div', {
  height: '3rem',
  alignItems: 'center',
  display: 'flex',
  flexFlow: 'row nowrap',

  '& > input': {
    fontSize: '$XL',
    background: '$dark',
    color: '$light',
    margin: '0 0.5rem',
    height: '1.5rem',
    width: '4rem',
    padding: '0.25rem',
    border: 'none',
    textAlign: 'center',
  },

  '& > span': {
    display: 'block',
    circle: '3rem',
    lineHeight: '2.8rem',
    fontSize: '2rem',
    cursor: 'pointer',
    textAlign: 'center',
    userSelect: 'none',

    '&:hover': {
      background: '$gray400',
    },
  },

  '@mobile': {
    display: 'none',
  },
})
