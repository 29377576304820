import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useCallback, VFC } from 'react'

import { AvailableLocale, localeNames, LOCALE_STORAGE } from '../constants'

import { Divider, Label, Language, Wrapper } from './LanguageSelector.styled'
import { initLanguage } from './LocaleInitializer'

const LanguageSelectorComponent: VFC<{ steps?: boolean; iframe?: boolean }> = ({ steps, iframe }) => {
  const { i18n } = useLingui()
  const selectedLocale = i18n.locale

  const selectLocale = useCallback((locale: AvailableLocale) => {
    localStorage.setItem(LOCALE_STORAGE, locale)
    initLanguage()
  }, [])

  return (
    <Wrapper dir="horizontal" steps={steps} iframe={iframe} className="languageSelector">
      <Label>
        <Trans id="languageSelector.label">OneID Sign offered in:</Trans>
      </Label>
      {Object.values(AvailableLocale).map((locale, index) => (
        <span key={index}>
          <Language
            onClick={selectedLocale !== locale ? () => selectLocale(locale) : undefined}
            active={selectedLocale === locale}
          >
            {localeNames[locale]}
          </Language>
          {index < Object.values(AvailableLocale).length - 1 && <Divider>{'|'}</Divider>}
        </span>
      ))}
    </Wrapper>
  )
}

LanguageSelectorComponent.toString = () => '.languageSelector'
export const LanguageSelector = LanguageSelectorComponent
