import { AnimatePresence } from 'framer-motion'
import React, { FC, ReactNode } from 'react'

import { LanguageSelector } from './LanguageSelector'
import { Spinner } from './Spinner'
import { ChildrenWrapper, Buttons, Header, InnerWrapper, Subtitle, Title } from './Page.styled'

export interface PageProps {
  title?: string | ReactNode
  subtitle?: string | ReactNode
  buttons?: ReactNode
  center?: boolean
  verticalCenter?: boolean
  wide?: boolean
  isLoading?: boolean
  loadingMessage?: string | ReactNode
}

export const Page: FC<PageProps> = ({
  children,
  buttons,
  title,
  subtitle,
  center,
  verticalCenter,
  wide,
  isLoading,
  loadingMessage,
}) => {
  return (
    <InnerWrapper>
      <ChildrenWrapper center={center} wide={wide} verticalCenter={verticalCenter}>
        {(title || subtitle) && (
          <Header>
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </Header>
        )}
        {children}
      </ChildrenWrapper>
      <Buttons>
        {buttons}
        <LanguageSelector />
      </Buttons>
      <AnimatePresence>{isLoading && <Spinner title={loadingMessage} />}</AnimatePresence>
    </InnerWrapper>
  )
}
