import { styled } from '../styles/stitches.config'

export const Wrapper = styled('div', {
  width: 'auto',
  fontSize: '$XXS',
  lineHeight: '1.75rem',
  margin: '1rem auto 3.5rem auto',
  display: 'flex',
  flexFlow: 'row nowrap',
  variants: {
    steps: {
      true: {
        '@desktop': {
          paddingInlineStart: '20.625rem',
        },
      },
    },
    iframe: {
      true: {
        '@mobile': {
          marginBottom: '1rem',
        },
        '@desktop': {
          display: 'none',
        },
      },
    },
  },
  '@mobile': {
    margin: '1rem auto',
  },
})

export const Divider = styled('span', {
  margin: '0 0.5rem',
})

export const Label = styled('span', {
  paddingInlineEnd: '0.5rem',
})

export const Language = styled('a', {
  cursor: 'pointer',
  color: '$primary',
  variants: {
    active: {
      true: {
        fontWeight: '$bold',
        textDecoration: 'underline',

        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
})
