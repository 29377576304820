import React from 'react'

import { styled } from '../../styles/stitches.config'
import { Text, TextProps, TextVariant } from '../../components'

export const Title = styled((props: TextProps) => <Text variant={TextVariant.Title} {...props} />, {
  marginTop: '1.25rem',
  marginBottom: '0.75rem',
})

export const Paragraph = styled((props: TextProps) => <Text variant={TextVariant.Normal} {...props} />, {
  marginBottom: '3rem',
})
