// @ts-nocheck
/* eslint-disable */
/**
 * OneID Signing API
 * OneID Signing API consists of two parts
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorMessage } from '../model';
// @ts-ignore
import { GetSigningRequestStatusResponse } from '../model';
// @ts-ignore
import { GetSigningRequestViewUrlResponse } from '../model';
// @ts-ignore
import { GetSigningResponse } from '../model';
// @ts-ignore
import { PostSigningResponse } from '../model';
// @ts-ignore
import { SigningRequestItem } from '../model';
/**
 * SigningControllerApi - axios parameter creator
 * @export
 */
export const SigningControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new signing request for the supplied documents. It is the entry point into the signing flow for the origin. 
         * @param {SigningRequestItem} signingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSigningRequest: async (requestParameters: SigningControllerApiCreateSigningRequestRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signingRequest' is not null or undefined
            assertParamExists('createSigningRequest', 'signingRequest', requestParameters.signingRequest)
            const localVarPath = `/api/signing/v1/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication OriginAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            if (requestParameters.signingRequest !== undefined) { 
                localVarFormParams.append('signingRequest', new Blob([JSON.stringify(requestParameters.signingRequest)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['content-type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the specified signing request. 
         * @param {string} signingRequestId ID of a signing request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSigningRequest: async (requestParameters: SigningControllerApiDeleteSigningRequestRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signingRequestId' is not null or undefined
            assertParamExists('deleteSigningRequest', 'signingRequestId', requestParameters.signingRequestId)
            const localVarPath = `/api/signing/v1/requests/{signingRequestId}`
                .replace(`{${"signingRequestId"}}`, encodeURIComponent(String(requestParameters.signingRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication OriginAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns signed document if status of the signing request is `completed` and signed document is not expired. 
         * @param {string} signingRequestId ID of a signing request
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (requestParameters: SigningControllerApiGetDocumentRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signingRequestId' is not null or undefined
            assertParamExists('getDocument', 'signingRequestId', requestParameters.signingRequestId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocument', 'documentId', requestParameters.documentId)
            const localVarPath = `/api/signing/v1/requests/{signingRequestId}/document/{documentId}`
                .replace(`{${"signingRequestId"}}`, encodeURIComponent(String(requestParameters.signingRequestId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication OriginAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the content of the signing request. 
         * @param {string} signingRequestId ID of a signing request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSigningRequest: async (requestParameters: SigningControllerApiGetSigningRequestRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signingRequestId' is not null or undefined
            assertParamExists('getSigningRequest', 'signingRequestId', requestParameters.signingRequestId)
            const localVarPath = `/api/signing/v1/requests/{signingRequestId}`
                .replace(`{${"signingRequestId"}}`, encodeURIComponent(String(requestParameters.signingRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication OriginAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the current status of the signing request. 
         * @param {string} signingRequestId ID of a signing request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSigningRequestStatus: async (requestParameters: SigningControllerApiGetSigningRequestStatusRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signingRequestId' is not null or undefined
            assertParamExists('getSigningRequestStatus', 'signingRequestId', requestParameters.signingRequestId)
            const localVarPath = `/api/signing/v1/requests/{signingRequestId}/status`
                .replace(`{${"signingRequestId"}}`, encodeURIComponent(String(requestParameters.signingRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication OriginAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a single use URL to which the origin should redirect an user-agent to complete signing of the document(s). 
         * @param {string} signingRequestId ID of a signing request
         * @param {string} redirectUri An URI the user-agent gets redirected to when the signing session either completes or fails. Redirect URI SHOULD use the https scheme; however, it MAY use the http scheme. The URI can contain any number of query parameters, however &#x60;error&#x60; and &#x60;error_description&#x60; are reserved as they are added in case of failure (e.g. &#x60;http://URI?state&#x3D;example&amp;error&#x3D;errorCode&amp;error_description&#x3D;errorDescription&#x60;). 
         * @param {string} [exchangeToken] OneID exchange token issued for the OneID Sign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSigningRequestViewUrl: async (requestParameters: SigningControllerApiGetSigningRequestViewUrlRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signingRequestId' is not null or undefined
            assertParamExists('getSigningRequestViewUrl', 'signingRequestId', requestParameters.signingRequestId)
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('getSigningRequestViewUrl', 'redirectUri', requestParameters.redirectUri)
            const localVarPath = `/api/signing/v1/requests/{signingRequestId}/view-url`
                .replace(`{${"signingRequestId"}}`, encodeURIComponent(String(requestParameters.signingRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication OriginAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)
            if (requestParameters.redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = requestParameters.redirectUri;
            }
            if (requestParameters.exchangeToken !== undefined) {
                localVarQueryParameter['exchangeToken'] = requestParameters.exchangeToken;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a user and revoke the corresponding customer with certificate. 
         * @param {string} userId ID of a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserAndRevokeCertificate: async (requestParameters: SigningControllerApiRemoveUserAndRevokeCertificateRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeUserAndRevokeCertificate', 'userId', requestParameters.userId)
            const localVarPath = `/api/signing/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication OriginAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};
/**
 * SigningControllerApi - functional programming interface
 * @export
 */
export const SigningControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SigningControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new signing request for the supplied documents. It is the entry point into the signing flow for the origin. 
         * @param {SigningRequestItem} signingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSigningRequest(requestParameters: SigningControllerApiCreateSigningRequestRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSigningResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSigningRequest(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the specified signing request. 
         * @param {string} signingRequestId ID of a signing request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSigningRequest(requestParameters: SigningControllerApiDeleteSigningRequestRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSigningRequest(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns signed document if status of the signing request is `completed` and signed document is not expired. 
         * @param {string} signingRequestId ID of a signing request
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(requestParameters: SigningControllerApiGetDocumentRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the content of the signing request. 
         * @param {string} signingRequestId ID of a signing request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSigningRequest(requestParameters: SigningControllerApiGetSigningRequestRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSigningResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSigningRequest(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the current status of the signing request. 
         * @param {string} signingRequestId ID of a signing request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSigningRequestStatus(requestParameters: SigningControllerApiGetSigningRequestStatusRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSigningRequestStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSigningRequestStatus(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a single use URL to which the origin should redirect an user-agent to complete signing of the document(s). 
         * @param {string} signingRequestId ID of a signing request
         * @param {string} redirectUri An URI the user-agent gets redirected to when the signing session either completes or fails. Redirect URI SHOULD use the https scheme; however, it MAY use the http scheme. The URI can contain any number of query parameters, however &#x60;error&#x60; and &#x60;error_description&#x60; are reserved as they are added in case of failure (e.g. &#x60;http://URI?state&#x3D;example&amp;error&#x3D;errorCode&amp;error_description&#x3D;errorDescription&#x60;). 
         * @param {string} [exchangeToken] OneID exchange token issued for the OneID Sign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSigningRequestViewUrl(requestParameters: SigningControllerApiGetSigningRequestViewUrlRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSigningRequestViewUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSigningRequestViewUrl(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove a user and revoke the corresponding customer with certificate. 
         * @param {string} userId ID of a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserAndRevokeCertificate(requestParameters: SigningControllerApiRemoveUserAndRevokeCertificateRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserAndRevokeCertificate(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};
/**
 * SigningControllerApi - factory interface
 * @export
 */
export const SigningControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SigningControllerApiFp(configuration)
    return {
        /**
         * Creates new signing request for the supplied documents. It is the entry point into the signing flow for the origin. 
         * @param {SigningRequestItem} signingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      createSigningRequest(requestParameters: SigningControllerApiCreateSigningRequestRequest,  options?: any): AxiosPromise<PostSigningResponse> {
            return localVarFp.createSigningRequest(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the specified signing request. 
         * @param {string} signingRequestId ID of a signing request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      deleteSigningRequest(requestParameters: SigningControllerApiDeleteSigningRequestRequest,  options?: any): AxiosPromise<void> {
            return localVarFp.deleteSigningRequest(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns signed document if status of the signing request is `completed` and signed document is not expired. 
         * @param {string} signingRequestId ID of a signing request
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      getDocument(requestParameters: SigningControllerApiGetDocumentRequest,  options?: any): AxiosPromise<any> {
            return localVarFp.getDocument(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the content of the signing request. 
         * @param {string} signingRequestId ID of a signing request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      getSigningRequest(requestParameters: SigningControllerApiGetSigningRequestRequest,  options?: any): AxiosPromise<GetSigningResponse> {
            return localVarFp.getSigningRequest(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the current status of the signing request. 
         * @param {string} signingRequestId ID of a signing request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      getSigningRequestStatus(requestParameters: SigningControllerApiGetSigningRequestStatusRequest,  options?: any): AxiosPromise<GetSigningRequestStatusResponse> {
            return localVarFp.getSigningRequestStatus(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a single use URL to which the origin should redirect an user-agent to complete signing of the document(s). 
         * @param {string} signingRequestId ID of a signing request
         * @param {string} redirectUri An URI the user-agent gets redirected to when the signing session either completes or fails. Redirect URI SHOULD use the https scheme; however, it MAY use the http scheme. The URI can contain any number of query parameters, however &#x60;error&#x60; and &#x60;error_description&#x60; are reserved as they are added in case of failure (e.g. &#x60;http://URI?state&#x3D;example&amp;error&#x3D;errorCode&amp;error_description&#x3D;errorDescription&#x60;). 
         * @param {string} [exchangeToken] OneID exchange token issued for the OneID Sign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      getSigningRequestViewUrl(requestParameters: SigningControllerApiGetSigningRequestViewUrlRequest,  options?: any): AxiosPromise<GetSigningRequestViewUrlResponse> {
            return localVarFp.getSigningRequestViewUrl(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a user and revoke the corresponding customer with certificate. 
         * @param {string} userId ID of a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      removeUserAndRevokeCertificate(requestParameters: SigningControllerApiRemoveUserAndRevokeCertificateRequest,  options?: any): AxiosPromise<void> {
            return localVarFp.removeUserAndRevokeCertificate(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * Request parameters for createSigningRequest operation in SigningControllerApi.
 * @export
 * @interface SigningControllerApiCreateSigningRequestRequest
 */
export interface SigningControllerApiCreateSigningRequestRequest {
    /**
     * 
     * @type {SigningRequestItem}
     * @memberof SigningControllerApiCreateSigningRequest
     */
    readonly signingRequest: SigningRequestItem
}
/**
 * Request parameters for deleteSigningRequest operation in SigningControllerApi.
 * @export
 * @interface SigningControllerApiDeleteSigningRequestRequest
 */
export interface SigningControllerApiDeleteSigningRequestRequest {
    /**
     * ID of a signing request
     * @type {string}
     * @memberof SigningControllerApiDeleteSigningRequest
     */
    readonly signingRequestId: string
}
/**
 * Request parameters for getDocument operation in SigningControllerApi.
 * @export
 * @interface SigningControllerApiGetDocumentRequest
 */
export interface SigningControllerApiGetDocumentRequest {
    /**
     * ID of a signing request
     * @type {string}
     * @memberof SigningControllerApiGetDocument
     */
    readonly signingRequestId: string
    /**
     * 
     * @type {string}
     * @memberof SigningControllerApiGetDocument
     */
    readonly documentId: string
}
/**
 * Request parameters for getSigningRequest operation in SigningControllerApi.
 * @export
 * @interface SigningControllerApiGetSigningRequestRequest
 */
export interface SigningControllerApiGetSigningRequestRequest {
    /**
     * ID of a signing request
     * @type {string}
     * @memberof SigningControllerApiGetSigningRequest
     */
    readonly signingRequestId: string
}
/**
 * Request parameters for getSigningRequestStatus operation in SigningControllerApi.
 * @export
 * @interface SigningControllerApiGetSigningRequestStatusRequest
 */
export interface SigningControllerApiGetSigningRequestStatusRequest {
    /**
     * ID of a signing request
     * @type {string}
     * @memberof SigningControllerApiGetSigningRequestStatus
     */
    readonly signingRequestId: string
}
/**
 * Request parameters for getSigningRequestViewUrl operation in SigningControllerApi.
 * @export
 * @interface SigningControllerApiGetSigningRequestViewUrlRequest
 */
export interface SigningControllerApiGetSigningRequestViewUrlRequest {
    /**
     * ID of a signing request
     * @type {string}
     * @memberof SigningControllerApiGetSigningRequestViewUrl
     */
    readonly signingRequestId: string
    /**
     * An URI the user-agent gets redirected to when the signing session either completes or fails. Redirect URI SHOULD use the https scheme; however, it MAY use the http scheme. The URI can contain any number of query parameters, however &#x60;error&#x60; and &#x60;error_description&#x60; are reserved as they are added in case of failure (e.g. &#x60;http://URI?state&#x3D;example&amp;error&#x3D;errorCode&amp;error_description&#x3D;errorDescription&#x60;). 
     * @type {string}
     * @memberof SigningControllerApiGetSigningRequestViewUrl
     */
    readonly redirectUri: string
    /**
     * OneID exchange token issued for the OneID Sign
     * @type {string}
     * @memberof SigningControllerApiGetSigningRequestViewUrl
     */
    readonly exchangeToken?: string
}
/**
 * Request parameters for removeUserAndRevokeCertificate operation in SigningControllerApi.
 * @export
 * @interface SigningControllerApiRemoveUserAndRevokeCertificateRequest
 */
export interface SigningControllerApiRemoveUserAndRevokeCertificateRequest {
    /**
     * ID of a user
     * @type {string}
     * @memberof SigningControllerApiRemoveUserAndRevokeCertificate
     */
    readonly userId: string
}
/**
 * SigningControllerApi - object-oriented interface
 * @export
 * @class SigningControllerApi
 * @extends {BaseAPI}
 */
export class SigningControllerApi extends BaseAPI {
    /**
     * Creates new signing request for the supplied documents. It is the entry point into the signing flow for the origin. 
     * @param {SigningControllerApiCreateSigningRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigningControllerApi
     */
    public createSigningRequest(requestParameters: SigningControllerApiCreateSigningRequestRequest, options?: AxiosRequestConfig) {
        return SigningControllerApiFp(this.configuration).createSigningRequest(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Deletes the specified signing request. 
     * @param {SigningControllerApiDeleteSigningRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigningControllerApi
     */
    public deleteSigningRequest(requestParameters: SigningControllerApiDeleteSigningRequestRequest, options?: AxiosRequestConfig) {
        return SigningControllerApiFp(this.configuration).deleteSigningRequest(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns signed document if status of the signing request is `completed` and signed document is not expired. 
     * @param {SigningControllerApiGetDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigningControllerApi
     */
    public getDocument(requestParameters: SigningControllerApiGetDocumentRequest, options?: AxiosRequestConfig) {
        return SigningControllerApiFp(this.configuration).getDocument(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets the content of the signing request. 
     * @param {SigningControllerApiGetSigningRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigningControllerApi
     */
    public getSigningRequest(requestParameters: SigningControllerApiGetSigningRequestRequest, options?: AxiosRequestConfig) {
        return SigningControllerApiFp(this.configuration).getSigningRequest(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets the current status of the signing request. 
     * @param {SigningControllerApiGetSigningRequestStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigningControllerApi
     */
    public getSigningRequestStatus(requestParameters: SigningControllerApiGetSigningRequestStatusRequest, options?: AxiosRequestConfig) {
        return SigningControllerApiFp(this.configuration).getSigningRequestStatus(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns a single use URL to which the origin should redirect an user-agent to complete signing of the document(s). 
     * @param {SigningControllerApiGetSigningRequestViewUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigningControllerApi
     */
    public getSigningRequestViewUrl(requestParameters: SigningControllerApiGetSigningRequestViewUrlRequest, options?: AxiosRequestConfig) {
        return SigningControllerApiFp(this.configuration).getSigningRequestViewUrl(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Remove a user and revoke the corresponding customer with certificate. 
     * @param {SigningControllerApiRemoveUserAndRevokeCertificateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigningControllerApi
     */
    public removeUserAndRevokeCertificate(requestParameters: SigningControllerApiRemoveUserAndRevokeCertificateRequest, options?: AxiosRequestConfig) {
        return SigningControllerApiFp(this.configuration).removeUserAndRevokeCertificate(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
}
