import React, { FC } from 'react'
import { motion, MotionProps } from 'framer-motion'

import { styled } from '../../styles/stitches.config'
import { Banner, LanguageSelector } from '../../components'

export const SignStepsWrapper = styled('div', {
  width: 'calc(100% - 3rem)',
  height: '100%',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  position: 'relative',
  padding: '0 3rem',

  [`& > ${LanguageSelector}`]: {
    '@mobile': {
      display: 'none',
    },
  },

  [`& > ${Banner}`]: {
    '@desktop': {
      display: 'none',
    },
  },

  '@mobile': {
    width: '100%',
    flexFlow: 'column nowrap',
    flex: 1,
    padding: 0,
  },
})

export const Content = styled('div', {
  position: 'relative',
  flex: 1,
  flexFlow: 'column nowrap',
  display: 'flex',
  borderRadius: '$L',
  borderWidth: '$2',
  borderStyle: 'solid',
  borderColor: '$gray200',
  background: '$gray50',
  overflow: 'hidden',

  '@mobile': {
    borderWidth: '$2 0 0 0',
    borderRadius: 0,
  },

  [`& > ${Banner}`]: {
    '@mobile': {
      display: 'none',
    },
  },
})

export const ContentWrapper = styled('div', {
  display: 'flex',
  flexFlow: 'column nowrap',
  boxSizing: 'border-box',
  flex: 1,
  overflow: 'hidden',

  '@desktop': {
    flexFlow: 'row nowrap',
    width: '100%',
    maxWidth: '1024px',
    margin: '0 auto',
  },

  variants: {
    noSteps: {
      true: {
        '@desktop': {
          maxWidth: '640px',
        },
      },
    },
  },
})

const StyledList = styled(motion.ul, {
  position: 'relative',
  width: '100%',
  marginTop: '4rem',
  padding: 0,
  listStyle: 'none',
  flex: '0 0 330px',

  '@mobile': {
    flex: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    marginTop: 0,
    marginBottom: '0.75rem',
  },
})

export const List: FC<MotionProps> = (props) => (
  <StyledList
    variants={{
      show: {
        transition: {
          staggerChildren: 0.1,
        },
      },
    }}
    initial="hidden"
    animate="show"
    {...props}
  />
)

const StyledCircle = styled(motion.span, {
  $$spanSize: '2.5rem',
  circle: '$$spanSize',
  position: 'relative',
  boxSizing: 'border-box',
  display: 'flex',
  flex: '0 0 $$spanSize',
  alignItems: 'center',
  justifyContent: 'center',
  marginInlineEnd: '1rem',
  borderWidth: '$2',
  borderStyle: 'solid',
  fontSize: '$XL',
  fontWeight: '$semiBold',
  background: '$light',
  color: '$secondarySkyBlue',

  '& svg path': {
    fill: 'currentColor',

    '@mobile': {
      square: '0.75rem',
    },
  },

  '@mobile': {
    $$spanSize: '1.5rem',
    marginInlineEnd: 0,
    marginBottom: '1rem',
    flex: '0 0 $$spanSize',
    square: '$$spanSize',
    fontSize: '$XS',
    fontWeight: '$normal',
  },
})

export const Circle: FC<MotionProps> = (props) => (
  <StyledCircle
    variants={{
      hidden: { x: 50, opacity: 0 },
      show: { x: 0, opacity: 1 },
    }}
    {...props}
  />
)

const StyledListItemContent = styled(motion.div, {
  flex: '1 1 auto',

  '@mobile': {
    textAlign: 'center',
  },

  '& h3': {
    fontSize: '$L',
    fontWeight: '$semiBold',
    lineHeight: 1.2,

    '@mobile': {
      fontSize: '$XS',
    },
  },

  '& p': {
    fontSize: '$S',
    color: '$gray800',

    '@mobile': {
      display: 'none',
    },
  },
})

export const ListItemContent: FC<MotionProps> = (props) => (
  <StyledListItemContent
    variants={{
      hidden: { x: 20, opacity: 0 },
      show: { x: 0, opacity: 1 },
    }}
    {...props}
  />
)

export const ListItem = styled(motion.li, {
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '1.25rem',
  borderWidth: '$2 0 $2 $2',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderRadius: '$L 0 0 $L',
  position: 'relative',

  '[dir="rtl"] &': {
    borderWidth: '$2 $2 $2 0',
    borderRadius: '0 $L $L 0',
    '&:after': {
      left: 'calc(-1 * $borderWidths$2)',
    },
  },

  '@mobile': {
    position: 'relative',
    border: 0,
    flexFlow: 'column nowrap',
    backgroundColor: 'transparent',
    padding: '0.5rem',

    '&:before': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: `calc(1.25rem - 1px)`,
      content: '',
      height: '$borderWidths$2',
      background: '$secondaryBeachBlue',
    },

    '&:first-child:before': {
      left: '50%',
    },

    '&:last-child:before': {
      right: '50%',
    },
  },

  '[dir="rtl"] &:first-child:before': {
    '@mobile': {
      left: 0,
      right: '50%',
    },
  },
  '[dir="rtl"] &:last-child:before': {
    '@mobile': {
      right: 0,
      left: '50%',
    },
  },

  variants: {
    active: {
      true: {
        background: '$gray50',
        borderColor: '$gray200',

        [`& ${StyledCircle}`]: {
          background: '$info100',
        },

        '@desktop': {
          '&:after': {
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: '100%',
            width: '$borderWidths$2',
            height: '100%',
            background: '$gray50',
            content: '',
          },
        },

        '@mobile': {
          background: 'transparent',
        },
      },
    },

    passed: {
      true: {
        [`& ${StyledCircle}`]: {
          color: '$light',
          borderColor: '$secondaryBeachBlue',
          backgroundColor: '$secondaryBeachBlue',
        },
      },
    },

    pending: {
      true: {
        [`& ${StyledCircle}`]: {
          border: 0,
          color: '$secondaryBeachBlue',
          borderColor: '$secondaryBeachBlue',
          backgroundColor: '$light',
        },
      },
    },

    error: {
      true: {
        [`& ${StyledCircle}`]: {
          color: '$error',
          backgroundColor: '$light',
        },
      },
    },
  },
})
