import { RedirectErrorCode } from '../_api/signing-be/generated'

import { ErrorDescriptions } from './errors'

interface CreateRedirectUrlParams {
  redirectUri: string
  signingRequestId?: string
  errorCode?: RedirectErrorCode
}

export const createRedirectUrl = ({ redirectUri, signingRequestId, errorCode }: CreateRedirectUrlParams) => {
  const url = new URL(redirectUri)

  if (signingRequestId) {
    url.searchParams.set('signing_request_id', signingRequestId)
  }

  if (errorCode) {
    url.searchParams.set('error', errorCode)
    url.searchParams.set('error_description', ErrorDescriptions[errorCode])
  }

  return url
}
