import React, { VFC } from 'react'
import { Trans } from '@lingui/macro'

import { StyledBenefitBox, BenefitBoxContent, BenefitLogo } from './BenefitBox.styled'

export const BenefitBox: VFC = () => (
  <StyledBenefitBox>
    <span>
      <Trans id="benefitBox.tagline">Trusted provider</Trans>
    </span>
    <BenefitLogo />
    <BenefitBoxContent>
      <Trans id="benefitBox.content">
        <h3>The Benefit Company</h3>
        <p>the top certification authority (CA) in Bahrain issuing advanced:</p>
        <ul>
          <li>certificates</li>
          <li>electronic signatures</li>
        </ul>
      </Trans>
    </BenefitBoxContent>
  </StyledBenefitBox>
)
