import { styled } from '../styles/stitches.config'

export const Title = styled('h2', {
  fontSize: '$XXL',
  lineHeight: 1.2,
  variants: {
    bold: {
      true: {
        fontWeight: '$bold',
      },
    },
  },
})

export const Normal = styled('p', {
  fontSize: '$S',
  lineHeight: 1.5,
  variants: {
    bold: {
      true: {
        fontWeight: '$bold',
      },
    },
  },
})
