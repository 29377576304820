import React, { VFC } from 'react'

import { StyledZoomControl } from './ZoomControl.styled'

export interface ZoomControlProps {
  magnification: number
  setMagnification: React.Dispatch<React.SetStateAction<number>>
}

export const ZoomControl: VFC<ZoomControlProps> = ({ magnification, setMagnification }) => (
  <StyledZoomControl>
    <span onClick={() => setMagnification((magnification) => magnification - 0.1)}>-</span>
    <input type="text" value={`${Math.round(magnification * 100)}%`} readOnly onClick={() => setMagnification(1)} />
    <span onClick={() => setMagnification((magnification) => magnification + 0.1)}>+</span>
  </StyledZoomControl>
)
