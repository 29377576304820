// @ts-nocheck
/* eslint-disable */
/**
 * OneID Signing API
 * OneID Signing API consists of two parts
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BatchResponse } from '../model';
// @ts-ignore
import { BenefitError } from '../model';
// @ts-ignore
import { CreatePadESRequestItem } from '../model';
// @ts-ignore
import { CreatePadESResponse } from '../model';
// @ts-ignore
import { CreateSigningBatchRequest } from '../model';
// @ts-ignore
import { MarkAsReadRequest } from '../model';
// @ts-ignore
import { MarkAsReadResponse } from '../model';
// @ts-ignore
import { UploadUserCertificateRequest } from '../model';
/**
 * WysiwysControllerApi - axios parameter creator
 * @export
 */
export const WysiwysControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a signature to each document in a batch with a given batch ID.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {Array<CreatePadESRequestItem>} [createPadESRequestItem] ok
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPadES:  (requestParameters: WysiwysControllerApiCreatePadESRequest,  options: AxiosRequestConfig = {}): RequestArgs => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createPadES', 'customerId', requestParameters.customerId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('createPadES', 'batchId', requestParameters.batchId)
            const localVarPath = `/benefitapi/wysiwys/api/v2/batches/{customerId}/{batchId}/signatures`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId)))
                .replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['content-type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.createPadESRequestItem, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a sign-session with the purpose of creating signatures for the documents in the batch with a given batch ID.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {string} [body] UTF-8 content supplied by Cryptomathic SDK
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSignSession:  (requestParameters: WysiwysControllerApiCreateSignSessionRequest,  options: AxiosRequestConfig = {}): RequestArgs => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createSignSession', 'customerId', requestParameters.customerId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('createSignSession', 'batchId', requestParameters.batchId)
            const localVarPath = `/benefitapi/wysiwys/api/v2/batches/{customerId}/{batchId}/signsession`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId)))
                .replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['content-type'] = 'text/plain';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.body, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a batch of documents to be browsed and signed.
         * @param {string} customerId 
         * @param {'pdf'} documentType 
         * @param {CreateSigningBatchRequest} [createSigningBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSigningBatch:  (requestParameters: WysiwysControllerApiCreateSigningBatchRequest,  options: AxiosRequestConfig = {}): RequestArgs => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createSigningBatch', 'customerId', requestParameters.customerId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('createSigningBatch', 'documentType', requestParameters.documentType)
            const localVarPath = `/benefitapi/wysiwys/api/v2/batches/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParameters.documentType !== undefined) {
                localVarQueryParameter['documentType'] = requestParameters.documentType;
            }

    
            localVarHeaderParameter['content-type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.createSigningBatchRequest, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Terminate the use of a given batch of documents in WYSIWYS.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBatch:  (requestParameters: WysiwysControllerApiDeleteBatchRequest,  options: AxiosRequestConfig = {}): RequestArgs => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteBatch', 'customerId', requestParameters.customerId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('deleteBatch', 'batchId', requestParameters.batchId)
            const localVarPath = `/benefitapi/wysiwys/api/v2/batches/{customerId}/{batchId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId)))
                .replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the meta-data for a batch with a given ID that exist in WYSIWYS.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatchMetadata:  (requestParameters: WysiwysControllerApiGetBatchMetadataRequest,  options: AxiosRequestConfig = {}): RequestArgs => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getBatchMetadata', 'customerId', requestParameters.customerId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('getBatchMetadata', 'batchId', requestParameters.batchId)
            const localVarPath = `/benefitapi/wysiwys/api/v2/batches/{customerId}/{batchId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId)))
                .replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the contents of a given page of a document with the given document ID in a batch with a given ID.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {string} documentId 
         * @param {number} pageNumber 
         * @param {number} w 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPage:  (requestParameters: WysiwysControllerApiGetDocumentPageRequest,  options: AxiosRequestConfig = {}): RequestArgs => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDocumentPage', 'customerId', requestParameters.customerId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('getDocumentPage', 'batchId', requestParameters.batchId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentPage', 'documentId', requestParameters.documentId)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getDocumentPage', 'pageNumber', requestParameters.pageNumber)
            // verify required parameter 'w' is not null or undefined
            assertParamExists('getDocumentPage', 'w', requestParameters.w)
            const localVarPath = `/benefitapi/wysiwys/api/v2/batches/{customerId}/{batchId}/documents/{documentId}/pages/{pageNumber}/contents`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId)))
                .replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId)))
                .replace(`{${"pageNumber"}}`, encodeURIComponent(String(requestParameters.pageNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (requestParameters.w !== undefined) {
                localVarQueryParameter['w'] = requestParameters.w;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the page meta-date of a specific page in a batch document.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {string} documentId 
         * @param {number} pageNumber 
         * @param {MarkAsReadRequest} [markAsReadRequest] Mark as ready request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markPageAsRead:  (requestParameters: WysiwysControllerApiMarkPageAsReadRequest,  options: AxiosRequestConfig = {}): RequestArgs => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('markPageAsRead', 'customerId', requestParameters.customerId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('markPageAsRead', 'batchId', requestParameters.batchId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('markPageAsRead', 'documentId', requestParameters.documentId)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('markPageAsRead', 'pageNumber', requestParameters.pageNumber)
            const localVarPath = `/benefitapi/wysiwys/api/v2/batches/{customerId}/{batchId}/documents/{documentId}/pages/{pageNumber}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId)))
                .replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId)))
                .replace(`{${"pageNumber"}}`, encodeURIComponent(String(requestParameters.pageNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['content-type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.markAsReadRequest, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing batch with a given ID with a user certificate.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {UploadUserCertificateRequest} [uploadUserCertificateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUserCertificate:  (requestParameters: WysiwysControllerApiUploadUserCertificateRequest,  options: AxiosRequestConfig = {}): RequestArgs => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('uploadUserCertificate', 'customerId', requestParameters.customerId)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('uploadUserCertificate', 'batchId', requestParameters.batchId)
            const localVarPath = `/benefitapi/wysiwys/api/v2/batches/{customerId}/{batchId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId)))
                .replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['content-type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.uploadUserCertificateRequest, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};
/**
 * WysiwysControllerApi - functional programming interface
 * @export
 */
export const WysiwysControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WysiwysControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a signature to each document in a batch with a given batch ID.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {Array<CreatePadESRequestItem>} [createPadESRequestItem] ok
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPadES(requestParameters: WysiwysControllerApiCreatePadESRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePadESResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPadES(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a sign-session with the purpose of creating signatures for the documents in the batch with a given batch ID.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {string} [body] UTF-8 content supplied by Cryptomathic SDK
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSignSession(requestParameters: WysiwysControllerApiCreateSignSessionRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSignSession(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a batch of documents to be browsed and signed.
         * @param {string} customerId 
         * @param {'pdf'} documentType 
         * @param {CreateSigningBatchRequest} [createSigningBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSigningBatch(requestParameters: WysiwysControllerApiCreateSigningBatchRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSigningBatch(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Terminate the use of a given batch of documents in WYSIWYS.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBatch(requestParameters: WysiwysControllerApiDeleteBatchRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBatch(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the meta-data for a batch with a given ID that exist in WYSIWYS.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatchMetadata(requestParameters: WysiwysControllerApiGetBatchMetadataRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatchMetadata(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the contents of a given page of a document with the given document ID in a batch with a given ID.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {string} documentId 
         * @param {number} pageNumber 
         * @param {number} w 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentPage(requestParameters: WysiwysControllerApiGetDocumentPageRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentPage(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the page meta-date of a specific page in a batch document.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {string} documentId 
         * @param {number} pageNumber 
         * @param {MarkAsReadRequest} [markAsReadRequest] Mark as ready request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markPageAsRead(requestParameters: WysiwysControllerApiMarkPageAsReadRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkAsReadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markPageAsRead(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing batch with a given ID with a user certificate.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {UploadUserCertificateRequest} [uploadUserCertificateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadUserCertificate(requestParameters: WysiwysControllerApiUploadUserCertificateRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUserCertificate(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};
/**
 * WysiwysControllerApi - factory interface
 * @export
 */
export const WysiwysControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WysiwysControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a signature to each document in a batch with a given batch ID.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {Array<CreatePadESRequestItem>} [createPadESRequestItem] ok
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      createPadES(requestParameters: WysiwysControllerApiCreatePadESRequest,  options?: any): AxiosPromise<CreatePadESResponse> {
            return localVarFp.createPadES(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a sign-session with the purpose of creating signatures for the documents in the batch with a given batch ID.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {string} [body] UTF-8 content supplied by Cryptomathic SDK
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      createSignSession(requestParameters: WysiwysControllerApiCreateSignSessionRequest,  options?: any): AxiosPromise<any> {
            return localVarFp.createSignSession(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a batch of documents to be browsed and signed.
         * @param {string} customerId 
         * @param {'pdf'} documentType 
         * @param {CreateSigningBatchRequest} [createSigningBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      createSigningBatch(requestParameters: WysiwysControllerApiCreateSigningBatchRequest,  options?: any): AxiosPromise<BatchResponse> {
            return localVarFp.createSigningBatch(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Terminate the use of a given batch of documents in WYSIWYS.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      deleteBatch(requestParameters: WysiwysControllerApiDeleteBatchRequest,  options?: any): AxiosPromise<void> {
            return localVarFp.deleteBatch(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the meta-data for a batch with a given ID that exist in WYSIWYS.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      getBatchMetadata(requestParameters: WysiwysControllerApiGetBatchMetadataRequest,  options?: any): AxiosPromise<BatchResponse> {
            return localVarFp.getBatchMetadata(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the contents of a given page of a document with the given document ID in a batch with a given ID.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {string} documentId 
         * @param {number} pageNumber 
         * @param {number} w 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      getDocumentPage(requestParameters: WysiwysControllerApiGetDocumentPageRequest,  options?: any): AxiosPromise<any> {
            return localVarFp.getDocumentPage(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the page meta-date of a specific page in a batch document.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {string} documentId 
         * @param {number} pageNumber 
         * @param {MarkAsReadRequest} [markAsReadRequest] Mark as ready request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      markPageAsRead(requestParameters: WysiwysControllerApiMarkPageAsReadRequest,  options?: any): AxiosPromise<MarkAsReadResponse> {
            return localVarFp.markPageAsRead(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing batch with a given ID with a user certificate.
         * @param {string} customerId 
         * @param {string} batchId 
         * @param {UploadUserCertificateRequest} [uploadUserCertificateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      uploadUserCertificate(requestParameters: WysiwysControllerApiUploadUserCertificateRequest,  options?: any): AxiosPromise<BatchResponse> {
            return localVarFp.uploadUserCertificate(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * Request parameters for createPadES operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiCreatePadESRequest
 */
export interface WysiwysControllerApiCreatePadESRequest {
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiCreatePadES
     */
    readonly customerId: string
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiCreatePadES
     */
    readonly batchId: string
    /**
     * ok
     * @type {Array<CreatePadESRequestItem>}
     * @memberof WysiwysControllerApiCreatePadES
     */
    readonly createPadESRequestItem?: Array<CreatePadESRequestItem>
}
/**
 * Request parameters for createSignSession operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiCreateSignSessionRequest
 */
export interface WysiwysControllerApiCreateSignSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiCreateSignSession
     */
    readonly customerId: string
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiCreateSignSession
     */
    readonly batchId: string
    /**
     * UTF-8 content supplied by Cryptomathic SDK
     * @type {string}
     * @memberof WysiwysControllerApiCreateSignSession
     */
    readonly body?: string
}
/**
 * Request parameters for createSigningBatch operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiCreateSigningBatchRequest
 */
export interface WysiwysControllerApiCreateSigningBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiCreateSigningBatch
     */
    readonly customerId: string
    /**
     * 
     * @type {'pdf'}
     * @memberof WysiwysControllerApiCreateSigningBatch
     */
    readonly documentType: 'pdf'
    /**
     * 
     * @type {CreateSigningBatchRequest}
     * @memberof WysiwysControllerApiCreateSigningBatch
     */
    readonly createSigningBatchRequest?: CreateSigningBatchRequest
}
/**
 * Request parameters for deleteBatch operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiDeleteBatchRequest
 */
export interface WysiwysControllerApiDeleteBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiDeleteBatch
     */
    readonly customerId: string
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiDeleteBatch
     */
    readonly batchId: string
}
/**
 * Request parameters for getBatchMetadata operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiGetBatchMetadataRequest
 */
export interface WysiwysControllerApiGetBatchMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiGetBatchMetadata
     */
    readonly customerId: string
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiGetBatchMetadata
     */
    readonly batchId: string
}
/**
 * Request parameters for getDocumentPage operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiGetDocumentPageRequest
 */
export interface WysiwysControllerApiGetDocumentPageRequest {
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiGetDocumentPage
     */
    readonly customerId: string
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiGetDocumentPage
     */
    readonly batchId: string
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiGetDocumentPage
     */
    readonly documentId: string
    /**
     * 
     * @type {number}
     * @memberof WysiwysControllerApiGetDocumentPage
     */
    readonly pageNumber: number
    /**
     * 
     * @type {number}
     * @memberof WysiwysControllerApiGetDocumentPage
     */
    readonly w: number
}
/**
 * Request parameters for markPageAsRead operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiMarkPageAsReadRequest
 */
export interface WysiwysControllerApiMarkPageAsReadRequest {
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiMarkPageAsRead
     */
    readonly customerId: string
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiMarkPageAsRead
     */
    readonly batchId: string
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiMarkPageAsRead
     */
    readonly documentId: string
    /**
     * 
     * @type {number}
     * @memberof WysiwysControllerApiMarkPageAsRead
     */
    readonly pageNumber: number
    /**
     * Mark as ready request
     * @type {MarkAsReadRequest}
     * @memberof WysiwysControllerApiMarkPageAsRead
     */
    readonly markAsReadRequest?: MarkAsReadRequest
}
/**
 * Request parameters for uploadUserCertificate operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiUploadUserCertificateRequest
 */
export interface WysiwysControllerApiUploadUserCertificateRequest {
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiUploadUserCertificate
     */
    readonly customerId: string
    /**
     * 
     * @type {string}
     * @memberof WysiwysControllerApiUploadUserCertificate
     */
    readonly batchId: string
    /**
     * 
     * @type {UploadUserCertificateRequest}
     * @memberof WysiwysControllerApiUploadUserCertificate
     */
    readonly uploadUserCertificateRequest?: UploadUserCertificateRequest
}
/**
 * WysiwysControllerApi - object-oriented interface
 * @export
 * @class WysiwysControllerApi
 * @extends {BaseAPI}
 */
export class WysiwysControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add a signature to each document in a batch with a given batch ID.
     * @param {WysiwysControllerApiCreatePadESRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public createPadES(requestParameters: WysiwysControllerApiCreatePadESRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).createPadES(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a sign-session with the purpose of creating signatures for the documents in the batch with a given batch ID.
     * @param {WysiwysControllerApiCreateSignSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public createSignSession(requestParameters: WysiwysControllerApiCreateSignSessionRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).createSignSession(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a batch of documents to be browsed and signed.
     * @param {WysiwysControllerApiCreateSigningBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public createSigningBatch(requestParameters: WysiwysControllerApiCreateSigningBatchRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).createSigningBatch(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Terminate the use of a given batch of documents in WYSIWYS.
     * @param {WysiwysControllerApiDeleteBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public deleteBatch(requestParameters: WysiwysControllerApiDeleteBatchRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).deleteBatch(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Retrieve the meta-data for a batch with a given ID that exist in WYSIWYS.
     * @param {WysiwysControllerApiGetBatchMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public getBatchMetadata(requestParameters: WysiwysControllerApiGetBatchMetadataRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).getBatchMetadata(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the contents of a given page of a document with the given document ID in a batch with a given ID.
     * @param {WysiwysControllerApiGetDocumentPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public getDocumentPage(requestParameters: WysiwysControllerApiGetDocumentPageRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).getDocumentPage(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the page meta-date of a specific page in a batch document.
     * @param {WysiwysControllerApiMarkPageAsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public markPageAsRead(requestParameters: WysiwysControllerApiMarkPageAsReadRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).markPageAsRead(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update an existing batch with a given ID with a user certificate.
     * @param {WysiwysControllerApiUploadUserCertificateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public uploadUserCertificate(requestParameters: WysiwysControllerApiUploadUserCertificateRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).uploadUserCertificate(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
}
