import React, { forwardRef, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { Page } from '../Document'

import { StyledDocumentDetailPage, ImageWrapper, Full, Thumbnail } from './DocumentDetailPage.styled'

export interface DocumentDetailPageProps {
  page: Page
  pageNumber: number
  magnification: number
  readPageCallback: (pageNumber: number) => void
}

export const DocumentDetailPage = forwardRef<HTMLLIElement, DocumentDetailPageProps>(
  ({ page, pageNumber, magnification, readPageCallback }, ref) => {
    const [loaded, setLoaded] = useState(false)
    const [inViewRef, inView] = useInView({
      trackVisibility: true,
      // TODO: Setting this to 0 crashes the app. I'm sure there's a better way to do this.
      delay: 150,
      threshold: 0.7,
      skip: !loaded,
    })

    useEffect(() => {
      if (inView) {
        readPageCallback(pageNumber)
      }
    }, [inView, readPageCallback, pageNumber])

    return (
      <StyledDocumentDetailPage
        key={page.full}
        onClick={() => readPageCallback(pageNumber)}
        id={`page${pageNumber}`}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false }}
        ref={ref}
      >
        <ImageWrapper
          css={{
            $$height: `calc(${magnification * 100}vh - 7rem)`,
          }}
        >
          <Thumbnail ref={inViewRef} src={page.thumbnail} alt="Page thumbnail" />
          <Full
            src={page.full}
            alt="Page"
            onLoad={() => {
              setLoaded(true)
            }}
            loaded={loaded}
            data-page-number={pageNumber}
          />
        </ImageWrapper>
      </StyledDocumentDetailPage>
    )
  },
)

DocumentDetailPage.displayName = 'DocumentDetailPage'
