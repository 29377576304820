import { styled } from '../styles/stitches.config'

import { Icon } from './Icon'

export const StyledBanner = styled('div', {
  boxSizing: 'border-box',
  width: 'calc(100% - 3rem)',
  margin: '1rem 1.5rem 0 1.5rem',
  padding: '0.25rem 0.5rem',
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  backgroundImage:
    'linear-gradient(45deg, #fffce0 25%, #fffacc 25%, #fffacc 50%, #fffce0 50%, #fffce0 75%, #fffacc 75%, #fffacc 100%)',
  backgroundSize: '3.5rem 3.5rem',
  fontSize: '$S',

  [`& > ${Icon}`]: {
    marginInlineEnd: '0.5rem',
    flex: '0 0 1.5rem',
  },
})
