import { datadogRum } from '@datadog/browser-rum'

import { config } from '../config'

export const datadogInit = () => {
  if (
    !config.datadog.applicationId ||
    !config.datadog.clientToken ||
    !config.datadog.service ||
    !config.datadog.version ||
    !config.datadog.site ||
    !config.datadog.env
  ) {
    console.warn('Datadog not configured!')
    return
  }

  datadogRum.init({
    applicationId: config.datadog.applicationId,
    clientToken: config.datadog.clientToken,
    service: config.datadog.service,
    version: config.datadog.version,
    site: config.datadog.site,
    env: config.datadog.env,
    sampleRate: 100,
    trackInteractions: false,
  })
}
