import { styled } from '../../styles/stitches.config'

export const Label = styled('label', {
  fontSize: '$L',
  '& strong': {
    fontWeight: '$semiBold',
  },
})

export const Textarea = styled('textarea', {
  display: 'block',
  boxSizing: 'border-box',
  resize: 'none',
  height: '8rem',
  padding: '1rem',
  borderWidth: '$hairline',
  borderStyle: 'solid',
  borderColor: '$gray400',
  borderRadius: '$S',
  fontSize: '$M',
})
