import { BatchContext } from '../../contexts/batch'
import { convertToBytes, hexToBytes } from '../../utils'

import type { Cryptomathic } from './interface/SignerUserSDK'

declare global {
  interface Window {
    Cryptomathic: typeof Cryptomathic
  }
}

export interface CryptomathicSdkCredentials {
  id: string
  modulus: string
  publicExponent: string
  keySize: number
}

export interface SignParams {
  batch: BatchContext
  samlToken: string
  cryptomathicSdkCredentials: CryptomathicSdkCredentials | undefined
  disableCryptomathic: boolean | undefined
}

export const sign = async ({
  batch,
  samlToken,
  cryptomathicSdkCredentials,
  disableCryptomathic,
}: SignParams): Promise<Uint8Array[]> => {
  const { signSessionURL, documents } = batch

  if (disableCryptomathic) {
    console.log('Cryptomathic disabled')
    return documents.map(() => new Uint8Array([1, 2, 3]))
  }

  if (!cryptomathicSdkCredentials) {
    throw new Error('Failed to get Cryptomathic SDK credentials')
  }

  window.Cryptomathic.namespace('Cryptomathic.SCK').Details = {
    KeyID: cryptomathicSdkCredentials.id,
    Modulus: cryptomathicSdkCredentials.modulus,
    PublicExponent: cryptomathicSdkCredentials.publicExponent,
    KeySize: cryptomathicSdkCredentials.keySize,
  }

  const sdk = new window.Cryptomathic.SignerUserSDK.SDK(signSessionURL, 5000)
  sdk.initialize()

  const encodedSaml = convertToBytes(samlToken)
  const policyList: Cryptomathic.Messages.PolicyEntry[] = await new Promise((resolve, reject) =>
    sdk.createSession((_, policyList) => resolve(policyList), reject, encodedSaml),
  )
  const keyEntry = policyList[0].KEY_LIST[0]

  const signatures: Uint8Array[] = []

  for (const document of documents) {
    const hashBytes = hexToBytes(document.hash)
    const signature = await new Promise<number[]>((resolve, reject) => sdk.sign(keyEntry, hashBytes, resolve, reject))
    signatures.push(new Uint8Array(signature))
  }

  await new Promise<void>((resolve, reject) => sdk.logoff(resolve, reject))

  return signatures
}
