// @ts-nocheck
/* eslint-disable */
/**
 * OneID Signing API
 * OneID Signing API consists of two parts
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const RedirectErrorCode = {
    IdentityMismatch: 'IDENTITY_MISMATCH',
    ConsentRejected: 'CONSENT_REJECTED',
    InsufficientLevelOfAssurance: 'INSUFFICIENT_LEVEL_OF_ASSURANCE',
    SigningFailed: 'SIGNING_FAILED',
    BatchError: 'BATCH_ERROR',
    GenericError: 'GENERIC_ERROR'
} as const;

export type RedirectErrorCode = typeof RedirectErrorCode[keyof typeof RedirectErrorCode];



