import React, { ReactNode, VFC } from 'react'
import cx from 'classnames'

import { StyledBanner } from './Banner.styled'
import { Icon } from './Icon'

export interface BannerProps {
  text: string | ReactNode
  className?: string
}

export const BannerComponent: VFC<BannerProps> = ({ className, text }) => (
  <StyledBanner className={cx('banner', className)}>
    <Icon name="Certificate" size="S" />
    {text}
  </StyledBanner>
)

BannerComponent.toString = () => '.banner'
export const Banner = BannerComponent
