import { styled } from '../styles/stitches.config'

export enum ButtonVariant {
  Primary = 'primary',
  Icon = 'icon',
  Outline = 'outline',
}

export enum ButtonTextVariant {
  M = 'm',
  S = 's',
}

export const StyledButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$light',
  fontWeight: '$semiBold',
  cursor: 'pointer',
  border: 0,
  transition: 'all 0.2s ease-in-out',
  width: '100%',

  '&[disabled]': {
    opacity: 0.5,
  },

  variants: {
    textSize: {
      [ButtonTextVariant.M]: {
        fontSize: '$M',
      },
      [ButtonTextVariant.S]: {
        fontSize: '$S',
      },
    },
    mode: {
      [ButtonVariant.Icon]: {
        background: 'transparent',
        circle: '3rem',
        '&:hover': {
          background: '$gray400',
        },
        '&:active': {
          background: '$gray800',
        },
        '& svg path': {
          fill: '$light',
        },
      },
      [ButtonVariant.Primary]: {
        background: '$primary',
        borderRadius: '$S',
        minHeight: '3rem',
        padding: '0 1rem',
        '&:hover:not([disabled])': {
          boxShadow: '0 0.15rem 0 $colors$primary50',
          transform: 'translateY(-0.15rem)',
        },
        '&:active:not([disabled])': {
          background: '$primary800',
          boxShadow: '0 0 0 $colors$gray400',
          transform: 'translateY(0)',
        },
        [`& svg`]: {
          marginInlineEnd: '0.5rem',
        },
      },
      [ButtonVariant.Outline]: {
        backgroundColor: '$light',
        color: '$primary',
        borderStyle: 'solid',
        borderWidth: '$1',
        borderRadius: '$S',
        minHeight: '3rem',
        padding: '0 1rem',
        '&:hover:not([disabled])': {
          boxShadow: '0 0.15rem 0 $colors$primary50',
          transform: 'translateY(-0.15rem)',
        },
        '&:active:not([disabled])': {
          background: '$light',
          boxShadow: '0 0 0 $colors$gray400',
          transform: 'translateY(0)',
        },
        [`& svg`]: {
          marginInlineEnd: '0.5rem',
        },
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
})

export const ButtonWrapper = styled('a', {
  textDecoration: 'none',
})
