import { styled } from '../../../styles/stitches.config'

export const List = styled('ul', {
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  boxSizing: 'border-box',
  width: '20%',
  height: '100%',
  listStyle: 'none',
  background: '$gray400',
  padding: '2rem 2rem 0 2rem',
  overflow: 'auto',

  '&::-webkit-scrollbar': {
    background: 'transparent',
    square: '0.5rem',
  },

  '&::-webkit-scrollbar-track': {
    background: '$gray200',
    borderRadius: '0.25rem',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '$dark',
    borderRadius: '0.25rem',
  },

  '@mobile': {
    display: 'none',
  },
})

export const ListItem = styled('li', {
  width: '55%',
  marginBottom: '2rem',
  cursor: 'pointer',

  '& span': {
    display: 'block',
    textAlign: 'center',
    fontSize: '$S',
  },

  '& img': {
    width: '100%',
    boxShadow: '0 0 0.25rem 0 rgba(0, 0, 0, 0.25)',
  },
})
