import React from 'react'

import { styled } from '../../styles/stitches.config'
import { Icon } from '../../components'

export const Title = styled('h2', {
  fontSize: '1.5rem',
  fontWeight: '$semiBold',
  maxWidth: '20rem',
  margin: '0.75rem auto',
})

const IconSignedStyled = styled(Icon, {
  variants: {
    color: {
      error: {
        '& *': {
          stroke: '$error',
        },
      },
      info: {
        '& *': {
          stroke: '$secondaryBeachBlue',
        },
      },
    },
  },

  defaultVariants: {
    color: 'info',
  },
})

export const IconOK = () => <IconSignedStyled name="SignOK" color="info" size="XL" />
export const IconNOK = () => <IconSignedStyled name="SignCanceled" color="error" size="XL" />
