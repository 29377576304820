import React, { ReactNode, VFC } from 'react'

import { Page } from '../../components'

import { IconError, Title } from './ErrorScreen.styled'

export interface ErrorScreenProps {
  buttons: ReactNode
  message: string | ReactNode
}

export const ErrorScreen: VFC<ErrorScreenProps> = ({ buttons, message }) => {
  return (
    <Page buttons={buttons} center verticalCenter>
      <IconError />
      <Title>{message}</Title>
    </Page>
  )
}
