import { styled } from '../styles/stitches.config'

// TODO: Lets just create an SVG with the complete logo instead
export const LogoWrapper = styled('div', {
  position: 'relative',
  width: '12rem',
  margin: '1.5rem auto 1rem auto',
  flex: '0 0 auto',
  cursor: 'pointer',

  '& span': {
    position: 'absolute',
    bottom: '-0.1rem',
    right: '-0.5rem',
    fontWeight: 'bold',
    textShadow: '-0.1rem -0.1rem 0 #fff, 0.1rem -0.1rem 0 #fff, -0.1rem 0.1rem 0 #fff, 0.1rem 0.1rem 0 #fff',
  },

  '@mobile': {
    margin: '1rem auto 0.5rem auto',
  },
})
