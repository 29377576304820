import { styled } from '../styles/stitches.config'

export const TipBox = styled('div', {
  color: '$error800',
  backgroundColor: '$error100',
  padding: '0.75rem',
  fontSize: '$XS',
  lineHeight: 1.5,
  marginBottom: '1.5rem',
  textAlign: 'start',
  borderRadius: '$S',
  width: '100%',
  boxSizing: 'border-box',
})
