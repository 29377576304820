import { Trans } from '@lingui/macro'
import React, { VFC } from 'react'

import { createRedirectUrl } from '../../services/url'
import { RedirectErrorCode } from '../../_api/signing-be/generated'
import { Button } from '../../components/Button'
import { ButtonVariant } from '../../components/Button.styled'

import { Exit } from './OneSignError'
import { ErrorScreen } from './ErrorScreen'

export interface InterruptionErrorProps {
  retry?: () => Promise<any> | any
  exit?: Exit & { errorCode: RedirectErrorCode }
}

export const InterruptionError: VFC<InterruptionErrorProps> = ({ retry, exit }) => {
  return (
    <ErrorScreen
      buttons={
        <>
          {retry && (
            <Button onClick={retry}>
              <Trans id="interruptionError.tryAgain">Try again</Trans>
            </Button>
          )}
          {exit && (
            <Button href={createRedirectUrl(exit).href} variant={ButtonVariant.Outline}>
              <Trans id="interruptionError.goBack">Go back to {exit.clientName}</Trans>
            </Button>
          )}
        </>
      }
      message={<Trans id="interruptionError.title">Signing interrupted</Trans>}
    />
  )
}
