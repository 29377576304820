import { createStitches } from '@stitches/react'

const PALETTE = {
  light: '#fff',
  gray50: '#fdfdfd',
  gray100: '#f4f4f4',
  gray150: '#eaeaea',
  gray200: '#e2e2e2',
  gray400: '#8a8a8a',
  gray800: '#434343',
  dark: '#000',
  green: '#6cb917',
  red: '#db3d23',
  orange: '#fff0e1',
}

const sizedFactory = (round: boolean) => (size: string) => ({
  width: size,
  height: size,
  borderRadius: round ? '50%' : undefined,
})

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
  theme: {
    colors: {
      ...PALETTE,
      text: PALETTE.dark,
      border: '#7c7c7c',
      error: PALETTE.red,
      error100: '#FFF0E1',
      error800: '#930F0F',
      ok: PALETTE.green,
      info100: '#edfcfb',
      primary: '#022e5f',
      primary50: '#86b2f9',
      primary100: '#c6e6f7',
      primary800: '#001237',
      secondaryBeachBlue: '#296693',
      secondarySkyBlue: '#6086ad',
    },
    fontSizes: {
      XXS: '0.625rem',
      XS: '0.75rem',
      S: '0.875rem',
      M: '1rem',
      L: '1.125rem',
      XL: '1.25rem',
      XXL: '1.375rem',
    },
    sizes: {
      iconS: '1rem',
      iconM: '1.5rem',
      iconL: '2rem',
      iconXL: '5.5rem',
    },
    fontWeights: {
      normal: '400',
      semiBold: '600',
      bold: 'bold',
    },
    borderWidths: {
      hairline: '1px',
      1: '0.0625rem',
      2: '0.125rem',
    },
    radii: {
      S: '0.25rem',
      M: '0.5rem',
      L: '0.75rem',
      XL: '1.25rem',
      XXL: '1.5rem',
    },
  },
  media: {
    smallMobile: '(max-width: 500px)',
    mobile: '(max-width: 768px)',
    desktop: '(min-width: 769px)',
  },
  utils: {
    square: sizedFactory(false),
    circle: sizedFactory(true),
    marginHorizontal: (value: string) => ({ marginLeft: value, marginRight: value }),
    marginVertical: (value: string) => ({ marginTop: value, marginBottom: value }),
    paddingHorizontal: (value: string) => ({ paddingLeft: value, paddingRight: value }),
    paddingVertical: (value: string) => ({ paddingTop: value, paddingBottom: value }),
  },
})
