import { Trans } from '@lingui/macro'
import { AnimatePresence } from 'framer-motion'
import { range } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { Button, Checkbox, Page } from '../../components'
import { ButtonVariant } from '../../components/Button.styled'
import { BatchDocument, useBatch } from '../../contexts/batch'
import { useIdentity } from '../../contexts/identity'
import { SESSION_QUERY_KEY, useSession } from '../../contexts/session'
import { ROUTES } from '../../routes'
import { createPageUrl } from '../../_api/benefit/api'
import { SignStep, SignSteps } from '../signing'

import { DocumentDetail } from './detail'
import { Document, OneIDDocument } from './Document'
import { Documents } from './DocumentsList.styled'
import { DenySigningConfirmationModal } from './DenySigningConfirmationModal'

const initUIDocuments = (customerId: string, batchId: string, documents: BatchDocument[], authToken: string) =>
  documents.map((document) => ({
    id: document.id,
    name: document.name,
    pages: range(1, document.pageCount + 1).map((pageNumber) => ({
      thumbnail: createPageUrl(customerId, batchId, document.id, pageNumber, 200, authToken),
      full: createPageUrl(customerId, batchId, document.id, pageNumber, 1000, authToken),
      isRead: false,
    })),
  }))

export const DocumentsList = () => {
  const history = useHistory()
  const { customerId, wysiwysToken } = useIdentity()
  const batch = useBatch()
  const queryClient = useQueryClient()
  const { wysiwysApi } = useSession()
  const [documents, setDocuments] = useState<OneIDDocument[]>(
    initUIDocuments(customerId, batch.batchId, batch.documents, wysiwysToken),
  )
  const [openedPage, setOpenedPage] = useState<null | { document: OneIDDocument; pageNumber: number }>(null)
  const [seenAll, setSeenAll] = useState(false)

  const markReadAndClose = useCallback(
    (selectedDocument: OneIDDocument, pageNumbers: number[]) => {
      // update read state for all pages in all documents
      const updatedDocs = documents.map((document) => ({
        ...document,
        pages: document.pages.map((page, index) => ({
          ...page,
          isRead: page.isRead || (pageNumbers.includes(index) && selectedDocument === document),
        })),
      }))
      setDocuments(updatedDocs)
      // check state of all document pages and update seenAll
      setSeenAll(updatedDocs.map((document) => document.pages.every((page) => page.isRead)).every((isRead) => isRead))
      setOpenedPage(null)
    },
    [documents, setDocuments],
  )

  const markOpenedPage = useCallback((document, pageNumber) => setOpenedPage({ document, pageNumber }), [])

  const viewBatchMutation = useMutation(() => {
    return Promise.all(
      batch.documents.flatMap((document) =>
        range(1, document.pageCount + 1).map((pageNumber) =>
          batch.benefitApi.markPageAsRead({
            customerId: customerId,
            batchId: batch.batchId,
            documentId: document.id,
            pageNumber,
            markAsReadRequest: {
              viewed: true,
            },
          }),
        ),
      ),
    )
  })
  const onSign = useCallback(async () => {
    await viewBatchMutation.mutateAsync()
    history.push(ROUTES.batchRoutes.sign)
  }, [viewBatchMutation, history])

  const [isDenyModalOpened, setIsDenyModalOpened] = useState<boolean>(false)

  const onDenyConfirmed = useCallback(
    async (reason: string) => {
      await wysiwysApi.rejectWysiwysSession({
        rejectWysiwysSessionRequest: {
          reason,
        },
      })

      queryClient.invalidateQueries(SESSION_QUERY_KEY)
    },
    [queryClient, wysiwysApi],
  )

  const onDenyRejected = () => {
    setIsDenyModalOpened(false)
  }

  const onDenyButtonClick = () => {
    setIsDenyModalOpened(true)
  }

  return (
    <>
      <SignSteps step={SignStep.Review}>
        <Page
          buttons={
            <>
              <Checkbox
                name="seenItAll"
                label={<Trans id="document.seenAll.checkbox">I have viewed all the documents</Trans>}
                onChange={() => {
                  setSeenAll(!seenAll)
                }}
                checked={seenAll}
              />
              <Button fullWidth disabled={!seenAll || viewBatchMutation.isLoading} onClick={onSign}>
                <Trans id="document.sign.button">Sign with OneID</Trans>
              </Button>
              <Button variant={ButtonVariant.Outline} onClick={onDenyButtonClick}>
                <Trans id="signDocuments.button.deny">Deny</Trans>
              </Button>
            </>
          }
          wide
        >
          <Documents>
            {documents.map((document, index) => (
              <Document document={document} key={document.id} documentNumber={index} setOpenedPage={markOpenedPage} />
            ))}
          </Documents>
          <AnimatePresence>
            {openedPage && (
              <DocumentDetail
                document={openedPage.document}
                pageNumber={openedPage.pageNumber}
                markReadAndClose={markReadAndClose}
              />
            )}
          </AnimatePresence>
        </Page>
      </SignSteps>
      {isDenyModalOpened && <DenySigningConfirmationModal onConfirm={onDenyConfirmed} onCancel={onDenyRejected} />}
    </>
  )
}
