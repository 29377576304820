import React from 'react'
import { Trans } from '@lingui/macro'

import { Button, Page } from '../../components'
import { Session } from '../../contexts/session'
import { createRedirectUrl } from '../../services/url'
import { SigningFailedError } from '../errors/SigningFailedError'

import { SignStep, SignSteps } from './SignSteps'
import { IconNOK, IconOK, Title } from './SignComplete.styled'

const createRedirectUrlWithRejectError = (uri: string) => {
  const url = new URL(uri)
  url.searchParams.set('error', 'user_rejected')
  url.searchParams.set('error_description', 'User has rejected signing of documents')

  return url.toString()
}

export const SignDeny = (redirectUri: string, clientName: string) => {
  return (
    <SignSteps step={SignStep.Sign} stepState="error">
      <Page
        buttons={
          <Button href={createRedirectUrlWithRejectError(redirectUri)}>
            <Trans id="signDeny.continueButton">Go back to {clientName}</Trans>
          </Button>
        }
        center
        verticalCenter
      >
        <IconNOK />
        <Title>
          <Trans id="signDeny.errorMessage">Documents have not been signed!</Trans>
        </Title>
      </Page>
    </SignSteps>
  )
}

const SignComplete = (redirectUri: string, clientName: string, signingRequestId: string) => {
  return (
    <SignSteps step={SignStep.Sign} stepState="passed">
      <Page
        buttons={
          <Button href={createRedirectUrl({ redirectUri, signingRequestId }).href}>
            <Trans id="signComplete.continueButton">Continue to {clientName}</Trans>
          </Button>
        }
        center
        verticalCenter
      >
        <IconOK />
        <Title>
          <Trans id="signComplete.successMessage">You have successfully signed your documents!</Trans>
        </Title>
      </Page>
    </SignSteps>
  )
}

export const SessionDone = ({ outcome, redirectUri, clientName, signingRequestId }: Session) => {
  if (outcome === 'completed') {
    return SignComplete(redirectUri, clientName, signingRequestId)
  }

  if (outcome === 'rejected') {
    return SignDeny(redirectUri, clientName)
  }

  return (
    <SignSteps step={SignStep.Sign} stepState="error">
      <SigningFailedError exit={{ redirectUri, signingRequestId, clientName }} />
    </SignSteps>
  )
}
