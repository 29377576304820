import React, { FC } from 'react'

import { Button } from '../../../components'
import { styled } from '../../../styles/stitches.config'

export const Heading = styled('div', {
  display: 'flex',
  flex: '0 0 3.5rem',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  height: '3.5rem',
  boxSizing: 'border-box',
  padding: '0.25rem 1.5rem',
  position: 'relative',
  zIndex: 1,

  '& > h2': {
    flex: '1 1 auto',
    fontSize: '$L',
    fontWeight: '$semiBold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '@desktop': {
      fontSize: '$XXL',
    },
  },

  '@desktop': {
    height: '6.5rem',
    padding: '0.75rem 2rem',
    boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
  },
})

export const Controls = styled('div', {
  flex: 1,
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',

  '@desktop': {
    flex: 0,
  },
})

export const Divider = styled('div', {
  flex: '0 0 $borderWidths$1',
  height: '2rem',
  backgroundColor: '$gray400',
  margin: '0 0.5rem',

  '@mobile': {
    display: 'none',
  },
})

const StyledButtonWrapper = styled('div', {
  flex: 0,

  '@desktop': {
    flex: 1,

    '& > button': {
      marginInlineStart: 'auto',
    },
  },
})

export const CloseButton: FC<{ onClick: () => void }> = ({ onClick, ...props }) => (
  <StyledButtonWrapper {...props}>
    <Button icon="Close" onClick={onClick} />
  </StyledButtonWrapper>
)
