import React, { useCallback, VFC } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { SESSION_QUERY_KEY, useSession } from '../../contexts/session'
import { ROUTES } from '../../routes'
import { AuthOperation, OidcCallbackSuccessMessage } from '../../_api/signing-be/generated'
import { OneIDOperation } from '../operations'

import { SignSteps } from './SignSteps'

export const Login: VFC = () => {
  const { wysiwysApi } = useSession()
  const history = useHistory()
  const queryClient = useQueryClient()

  const onLoginComplete = useCallback(
    async (callbackSuccessMessage: OidcCallbackSuccessMessage) => {
      const {
        data: { customerId },
      } = await wysiwysApi.completeLogin({ completeOneIdOperationRequest: callbackSuccessMessage })

      queryClient.invalidateQueries(SESSION_QUERY_KEY)

      history.push(customerId ? ROUTES.batchRoutes.root : ROUTES.certificate)
    },
    [history, wysiwysApi, queryClient],
  )

  return (
    <SignSteps displaySteps={false}>
      <OneIDOperation operation={AuthOperation.Login} onComplete={onLoginComplete} />
    </SignSteps>
  )
}
