// @ts-nocheck
/* eslint-disable */
/**
 * OneID Signing API
 * OneID Signing API consists of two parts
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompleteIssueCertificateResponse } from '../model';
// @ts-ignore
import { CompleteLoginResponse } from '../model';
// @ts-ignore
import { CompleteOneIdOperationRequest } from '../model';
// @ts-ignore
import { CompleteSignResponse } from '../model';
// @ts-ignore
import { ErrorMessage } from '../model';
// @ts-ignore
import { GetNewInteractionUrlRequest } from '../model';
// @ts-ignore
import { GetNewInteractionUrlResponse } from '../model';
// @ts-ignore
import { GetWysiwysSessionResponse } from '../model';
// @ts-ignore
import { InitWysiwysSessionRequest } from '../model';
// @ts-ignore
import { InitWysiwysSessionResponse } from '../model';
// @ts-ignore
import { RejectWysiwysSessionRequest } from '../model';
/**
 * WysiwysControllerApi - axios parameter creator
 * @export
 */
export const WysiwysControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CompleteOneIdOperationRequest} completeOneIdOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeIssueCertificate: async (requestParameters: WysiwysControllerApiCompleteIssueCertificateRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeOneIdOperationRequest' is not null or undefined
            assertParamExists('completeIssueCertificate', 'completeOneIdOperationRequest', requestParameters.completeOneIdOperationRequest)
            const localVarPath = `/api/wysiwys/auth/complete-issue-certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication WysiwysAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

    
            localVarHeaderParameter['content-type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.completeOneIdOperationRequest, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompleteOneIdOperationRequest} completeOneIdOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeLogin: async (requestParameters: WysiwysControllerApiCompleteLoginRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeOneIdOperationRequest' is not null or undefined
            assertParamExists('completeLogin', 'completeOneIdOperationRequest', requestParameters.completeOneIdOperationRequest)
            const localVarPath = `/api/wysiwys/auth/complete-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication WysiwysAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

    
            localVarHeaderParameter['content-type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.completeOneIdOperationRequest, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompleteOneIdOperationRequest} completeOneIdOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeSign: async (requestParameters: WysiwysControllerApiCompleteSignRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeOneIdOperationRequest' is not null or undefined
            assertParamExists('completeSign', 'completeOneIdOperationRequest', requestParameters.completeOneIdOperationRequest)
            const localVarPath = `/api/wysiwys/auth/complete-sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication WysiwysAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

    
            localVarHeaderParameter['content-type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.completeOneIdOperationRequest, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeWysiwysSession: async ( options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/wysiwys/session/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication WysiwysAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetNewInteractionUrlRequest} getNewInteractionUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewInteractionURL: async (requestParameters: WysiwysControllerApiGetNewInteractionURLRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getNewInteractionUrlRequest' is not null or undefined
            assertParamExists('getNewInteractionURL', 'getNewInteractionUrlRequest', requestParameters.getNewInteractionUrlRequest)
            const localVarPath = `/api/wysiwys/auth/interaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication WysiwysAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

    
            localVarHeaderParameter['content-type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.getNewInteractionUrlRequest, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWysiwysSession: async ( options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/wysiwys/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication WysiwysAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InitWysiwysSessionRequest} initWysiwysSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWysiwysSession:  (requestParameters: WysiwysControllerApiInitWysiwysSessionRequest,  options: AxiosRequestConfig = {}): RequestArgs => {
            // verify required parameter 'initWysiwysSessionRequest' is not null or undefined
            assertParamExists('initWysiwysSession', 'initWysiwysSessionRequest', requestParameters.initWysiwysSessionRequest)
            const localVarPath = `/api/wysiwys/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['content-type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.initWysiwysSessionRequest, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RejectWysiwysSessionRequest} rejectWysiwysSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectWysiwysSession: async (requestParameters: WysiwysControllerApiRejectWysiwysSessionRequest,  options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rejectWysiwysSessionRequest' is not null or undefined
            assertParamExists('rejectWysiwysSession', 'rejectWysiwysSessionRequest', requestParameters.rejectWysiwysSessionRequest)
            const localVarPath = `/api/wysiwys/session/reject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication WysiwysAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

    
            localVarHeaderParameter['content-type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestParameters.rejectWysiwysSessionRequest, localVarRequestOptions, configuration)
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};
/**
 * WysiwysControllerApi - functional programming interface
 * @export
 */
export const WysiwysControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WysiwysControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CompleteOneIdOperationRequest} completeOneIdOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeIssueCertificate(requestParameters: WysiwysControllerApiCompleteIssueCertificateRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteIssueCertificateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeIssueCertificate(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompleteOneIdOperationRequest} completeOneIdOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeLogin(requestParameters: WysiwysControllerApiCompleteLoginRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeLogin(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompleteOneIdOperationRequest} completeOneIdOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeSign(requestParameters: WysiwysControllerApiCompleteSignRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteSignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeSign(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeWysiwysSession( options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeWysiwysSession( options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetNewInteractionUrlRequest} getNewInteractionUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewInteractionURL(requestParameters: WysiwysControllerApiGetNewInteractionURLRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewInteractionUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewInteractionURL(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWysiwysSession( options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWysiwysSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWysiwysSession( options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InitWysiwysSessionRequest} initWysiwysSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initWysiwysSession(requestParameters: WysiwysControllerApiInitWysiwysSessionRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitWysiwysSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initWysiwysSession(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RejectWysiwysSessionRequest} rejectWysiwysSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectWysiwysSession(requestParameters: WysiwysControllerApiRejectWysiwysSessionRequest,  options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectWysiwysSession(requestParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};
/**
 * WysiwysControllerApi - factory interface
 * @export
 */
export const WysiwysControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WysiwysControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CompleteOneIdOperationRequest} completeOneIdOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      completeIssueCertificate(requestParameters: WysiwysControllerApiCompleteIssueCertificateRequest,  options?: any): AxiosPromise<CompleteIssueCertificateResponse> {
            return localVarFp.completeIssueCertificate(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompleteOneIdOperationRequest} completeOneIdOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      completeLogin(requestParameters: WysiwysControllerApiCompleteLoginRequest,  options?: any): AxiosPromise<CompleteLoginResponse> {
            return localVarFp.completeLogin(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompleteOneIdOperationRequest} completeOneIdOperationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      completeSign(requestParameters: WysiwysControllerApiCompleteSignRequest,  options?: any): AxiosPromise<CompleteSignResponse> {
            return localVarFp.completeSign(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      completeWysiwysSession( options?: any): AxiosPromise<void> {
            return localVarFp.completeWysiwysSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetNewInteractionUrlRequest} getNewInteractionUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      getNewInteractionURL(requestParameters: WysiwysControllerApiGetNewInteractionURLRequest,  options?: any): AxiosPromise<GetNewInteractionUrlResponse> {
            return localVarFp.getNewInteractionURL(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      getWysiwysSession( options?: any): AxiosPromise<GetWysiwysSessionResponse> {
            return localVarFp.getWysiwysSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InitWysiwysSessionRequest} initWysiwysSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      initWysiwysSession(requestParameters: WysiwysControllerApiInitWysiwysSessionRequest,  options?: any): AxiosPromise<InitWysiwysSessionResponse> {
            return localVarFp.initWysiwysSession(requestParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RejectWysiwysSessionRequest} rejectWysiwysSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      rejectWysiwysSession(requestParameters: WysiwysControllerApiRejectWysiwysSessionRequest,  options?: any): AxiosPromise<void> {
            return localVarFp.rejectWysiwysSession(requestParameters, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * Request parameters for completeIssueCertificate operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiCompleteIssueCertificateRequest
 */
export interface WysiwysControllerApiCompleteIssueCertificateRequest {
    /**
     * 
     * @type {CompleteOneIdOperationRequest}
     * @memberof WysiwysControllerApiCompleteIssueCertificate
     */
    readonly completeOneIdOperationRequest: CompleteOneIdOperationRequest
}
/**
 * Request parameters for completeLogin operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiCompleteLoginRequest
 */
export interface WysiwysControllerApiCompleteLoginRequest {
    /**
     * 
     * @type {CompleteOneIdOperationRequest}
     * @memberof WysiwysControllerApiCompleteLogin
     */
    readonly completeOneIdOperationRequest: CompleteOneIdOperationRequest
}
/**
 * Request parameters for completeSign operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiCompleteSignRequest
 */
export interface WysiwysControllerApiCompleteSignRequest {
    /**
     * 
     * @type {CompleteOneIdOperationRequest}
     * @memberof WysiwysControllerApiCompleteSign
     */
    readonly completeOneIdOperationRequest: CompleteOneIdOperationRequest
}
/**
 * Request parameters for getNewInteractionURL operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiGetNewInteractionURLRequest
 */
export interface WysiwysControllerApiGetNewInteractionURLRequest {
    /**
     * 
     * @type {GetNewInteractionUrlRequest}
     * @memberof WysiwysControllerApiGetNewInteractionURL
     */
    readonly getNewInteractionUrlRequest: GetNewInteractionUrlRequest
}
/**
 * Request parameters for initWysiwysSession operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiInitWysiwysSessionRequest
 */
export interface WysiwysControllerApiInitWysiwysSessionRequest {
    /**
     * 
     * @type {InitWysiwysSessionRequest}
     * @memberof WysiwysControllerApiInitWysiwysSession
     */
    readonly initWysiwysSessionRequest: InitWysiwysSessionRequest
}
/**
 * Request parameters for rejectWysiwysSession operation in WysiwysControllerApi.
 * @export
 * @interface WysiwysControllerApiRejectWysiwysSessionRequest
 */
export interface WysiwysControllerApiRejectWysiwysSessionRequest {
    /**
     * 
     * @type {RejectWysiwysSessionRequest}
     * @memberof WysiwysControllerApiRejectWysiwysSession
     */
    readonly rejectWysiwysSessionRequest: RejectWysiwysSessionRequest
}
/**
 * WysiwysControllerApi - object-oriented interface
 * @export
 * @class WysiwysControllerApi
 * @extends {BaseAPI}
 */
export class WysiwysControllerApi extends BaseAPI {
    /**
     * 
     * @param {WysiwysControllerApiCompleteIssueCertificateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public completeIssueCertificate(requestParameters: WysiwysControllerApiCompleteIssueCertificateRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).completeIssueCertificate(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {WysiwysControllerApiCompleteLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public completeLogin(requestParameters: WysiwysControllerApiCompleteLoginRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).completeLogin(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {WysiwysControllerApiCompleteSignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public completeSign(requestParameters: WysiwysControllerApiCompleteSignRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).completeSign(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public completeWysiwysSession(options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).completeWysiwysSession(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {WysiwysControllerApiGetNewInteractionURLRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public getNewInteractionURL(requestParameters: WysiwysControllerApiGetNewInteractionURLRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).getNewInteractionURL(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public getWysiwysSession(options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).getWysiwysSession(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {WysiwysControllerApiInitWysiwysSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public initWysiwysSession(requestParameters: WysiwysControllerApiInitWysiwysSessionRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).initWysiwysSession(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {WysiwysControllerApiRejectWysiwysSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WysiwysControllerApi
     */
    public rejectWysiwysSession(requestParameters: WysiwysControllerApiRejectWysiwysSessionRequest, options?: AxiosRequestConfig) {
        return WysiwysControllerApiFp(this.configuration).rejectWysiwysSession(requestParameters,options).then((request) => request(this.axios, this.basePath));
    }
}
