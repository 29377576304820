import { Trans } from '@lingui/macro'
import React, { VFC } from 'react'

import { BenefitBox, Button, Expandable, Page } from '../../components'
import { BenefitBoxFooter, BenefitBoxFooterLink } from '../../components/BenefitBox.styled'

export const CreateCertificateInfo: VFC<{ toggleInfo: (nextValue?: boolean | undefined) => void }> = ({
  toggleInfo,
}) => (
  <Page
    buttons={
      <>
        <BenefitBoxFooter>
          <Trans id="createCertificateInfo.termsOfUse.infoText">
            By tapping to “<Trans id="createCertificateInfo.button.start">Start</Trans>” you agree to Benefit{' '}
            <BenefitBoxFooterLink href={window.ENV_BENEFIT_TERMS_OF_USE_LINK} target="_blank" rel="noreferrer">
              Terms of use
            </BenefitBoxFooterLink>
          </Trans>
        </BenefitBoxFooter>
        <Button onClick={() => toggleInfo(false)}>
          <Trans id="createCertificateInfo.button.start">Start</Trans>
        </Button>
      </>
    }
    title={<Trans id="createCertificateInfo.title">Create certificate</Trans>}
    subtitle={<Trans id="createCertificateInfo.subtitle">Connect a secure digital key with your identity</Trans>}
  >
    <BenefitBox />
    <Expandable
      title={<Trans id="createCertificateInfo.expandableWhat.title">What is a digital signature certificate?</Trans>}
    >
      <Trans id="createCertificateInfo.expandableWhat.content">
        It’s a secure digital key that certifies the identity of the holder
      </Trans>
    </Expandable>
    <Expandable title={<Trans id="createCertificateInfo.expandableWhy.title">Why I need it?</Trans>}>
      <Trans id="createCertificateInfo.expandableWhy.content">
        It provides you with a high level of security needed to electronically sign a document and proves it comes from
        a legitimate source.
      </Trans>
    </Expandable>
  </Page>
)
