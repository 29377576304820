import React, { VFC } from 'react'

import { OneIDDocument } from '../Document'

import { List, ListItem } from './DocumentDetailNavigator.styled'

export interface DocumentDetailNavigatorProps {
  pages: OneIDDocument['pages']
  name: OneIDDocument['name']
}

export const DocumentDetailNavigator: VFC<OneIDDocument> = ({ pages, name }) => (
  <List>
    {pages.map((page, index) => (
      <ListItem
        key={page.thumbnail}
        onClick={() => document.getElementById(`page${index}`)?.scrollIntoView({ behavior: 'smooth' })}
      >
        <img src={page.thumbnail} alt={name} />
        <span>{index + 1}</span>
      </ListItem>
    ))}
  </List>
)
