import React, { FC, ReactNode } from 'react'
import { MotionProps } from 'framer-motion'

import { useToggle } from '../hooks'

import { Caret, Content, Toggle, Wrapper } from './Expandable.styled'

export interface ExpandableProps extends MotionProps {
  title: string | ReactNode
  expanded?: boolean
}

export const Expandable: FC<ExpandableProps> = ({ children, title, expanded = false, ...props }) => {
  const [isExpanded, setExpanded] = useToggle(expanded)
  return (
    <Wrapper {...props}>
      <Toggle onClick={() => setExpanded(!isExpanded)}>
        {title}
        <Caret expanded={isExpanded} />
      </Toggle>
      <Content expanded={isExpanded}>{children}</Content>
    </Wrapper>
  )
}
