import { Trans } from '@lingui/macro'
import React, { VFC } from 'react'

import { createRedirectUrl } from '../../services/url'
import { Button } from '../../components/Button'
import { ButtonVariant } from '../../components/Button.styled'
import { RedirectErrorCode } from '../../_api/signing-be/generated'

import { ErrorScreen } from './ErrorScreen'
import { Exit } from './OneSignError'

export interface SigningFailedErrorProps {
  retry?: () => Promise<any> | any
  exit: Exit
}

export const SigningFailedError: VFC<SigningFailedErrorProps> = ({ retry, exit }) => {
  return (
    <ErrorScreen
      buttons={
        <>
          {retry && (
            <Button onClick={retry}>
              <Trans id="signingFailedError.tryAgain">Try again</Trans>
            </Button>
          )}
          {exit && (
            <Button
              href={createRedirectUrl({ errorCode: RedirectErrorCode.SigningFailed, ...exit }).href}
              variant={ButtonVariant.Outline}
            >
              <Trans id="signingFailedError.goBack">Go back to {exit.clientName}</Trans>
            </Button>
          )}
        </>
      }
      message={<Trans id="signingFailedError.title">Signing failed</Trans>}
    />
  )
}
