import React, { VFC } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import { LocaleInitializer } from './components/LocaleInitializer'
import { BatchContextProvider } from './contexts/batch'
import { IdentityContextProvider } from './contexts/identity'
import { SessionContextProvider } from './contexts/session'
import { ROUTES } from './routes'
import { Certificate, DocumentsList, Login, SignDocuments } from './screens'
import { globalStyles } from './styles/globalStyles'

const queryClient = new QueryClient()

const AuthorizedRoutes: VFC = () => {
  return (
    <IdentityContextProvider>
      <BatchContextProvider>
        <Switch>
          <Route path={ROUTES.batchRoutes.review}>
            <DocumentsList />
          </Route>
          <Route path={ROUTES.batchRoutes.sign}>
            <SignDocuments />
          </Route>
          <Redirect from={ROUTES.batchRoutes.root} to={ROUTES.batchRoutes.review} />
        </Switch>
      </BatchContextProvider>
    </IdentityContextProvider>
  )
}

export const App: VFC = () => {
  globalStyles()

  return (
    <LocaleInitializer>
      <QueryClientProvider client={queryClient}>
        <Router>
          <SessionContextProvider>
            <Switch>
              <Route path={ROUTES.signIn}>
                <Login />
              </Route>
              <Route path={ROUTES.certificate}>
                <Certificate />
              </Route>
              <Route path={ROUTES.batchRoutes.root}>
                <AuthorizedRoutes />
              </Route>
              <Redirect to={ROUTES.batchRoutes.root} />
            </Switch>
          </SessionContextProvider>
        </Router>
      </QueryClientProvider>
    </LocaleInitializer>
  )
}
