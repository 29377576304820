import React from 'react'
import { motion, MotionProps } from 'framer-motion'

import { keyframes, styled } from '../styles/stitches.config'

const ldsRing = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
})

export const StyledSpinner = styled(motion.div, {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '$S',
  backgroundColor: '$light',
  padding: '0.5rem 0.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column nowrap',
})

export const SpinnerText = styled('span', {
  marginTop: '0.5rem',
  fontSize: '$XXS',
})

export const SpinnerVisual = styled(motion.div, {
  $$iconSize: 'calc($$size / 2)',
  width: '$$size',
  height: '$$size',
  position: 'relative',

  '& svg': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    '& path': {
      fill: '$secondaryBeachBlue',
    },
  },
})

export const SpinnerPart = styled(motion.div, {
  boxSizing: 'border-box',
  display: 'block',
  position: 'absolute',
  width: 'calc($$size * 0.8)',
  height: 'calc($$size * 0.8)',
  margin: 'calc($$size * 0.1)',
  border: 'calc($$size * 0.05) solid $secondaryBeachBlue',
  borderRadius: '50%',
  animationName: ldsRing(),
  animationDuration: '1.2s',
  animationTimingFunction: 'cubic-bezier(0.5, 0, 0.5, 1)',
  animationIterationCount: 'infinite',
  borderColor: '$secondaryBeachBlue transparent transparent transparent',

  variants: {
    bare: {
      true: {
        margin: 0,
        width: '$$size',
        height: '$$size',
        borderWidth: 'calc($$size * 0.1)',
      },
    },
    animationDelay: {
      short: {
        animationDelay: '-0.15s',
      },
      normal: {
        animationDelay: '-0.3s',
      },
      long: {
        animationDelay: '-0.45s',
      },
    },
  },
})

export const SpinnerShade = styled((props: MotionProps) => <motion.div {...props} />, {
  background: 'rgba(0, 0, 0, 0.4)',
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
})
