import React, { FC } from 'react'

import { Title, Normal } from './Text.styled'

export enum TextVariant {
  Title = 'title',
  Normal = 'normal',
}

export interface TextProps {
  variant?: TextVariant
  bold?: boolean
}

export const Text: FC<TextProps> = ({ variant = TextVariant.Normal, ...rest }) => {
  const typographyComponents = {
    [TextVariant.Title]: <Title {...rest} />,
    [TextVariant.Normal]: <Normal {...rest} />,
  }
  return typographyComponents[variant]
}
