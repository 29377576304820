import { motion } from 'framer-motion'

import { styled } from '../../../styles/stitches.config'

export const ImageWrapper = styled('div', {
  position: 'relative',
  width: '100%',

  '@desktop': {
    width: 'unset',
    maxWidth: '100%',
    height: '$$height',
  },
})

export const Thumbnail = styled('img', {
  width: '100%',

  '@desktop': {
    width: 'unset',
    height: '100%',
  },
})

export const Full = styled('img', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  transition: 'opacity 0.25s ease-in-out',

  '@desktop': {
    width: 'unset',
    height: '100%',
  },

  variants: {
    loaded: {
      false: {
        opacity: '0',
      },
    },
  },
})

export const StyledDocumentDetailPage = styled(motion.li, {
  boxSizing: 'border-box',
  padding: '0 1.25rem 1.25rem 1.25rem',
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',

  '@desktop': {
    padding: '1.25rem',
  },
})
