import React, { VFC } from 'react'

import { OneIDDocument } from '../Document'

import { PaginationIndicator } from './PaginationIndicator'
import { ZoomControl, ZoomControlProps } from './ZoomControl'
import { Controls, CloseButton, Divider, Heading } from './DocumentDetailHeader.styled'

export interface DocumentDetailHeaderProps extends ZoomControlProps {
  document: OneIDDocument
  currentPage: number
  close: () => void
}

export const DocumentDetailHeader: VFC<DocumentDetailHeaderProps> = ({
  document,
  close,
  magnification,
  setMagnification,
  currentPage,
}) => {
  return (
    <Heading>
      <h2>{document.name}</h2>
      <Controls>
        <PaginationIndicator totalPages={document.pages.length} currentPage={currentPage} />
        <Divider />
        <ZoomControl magnification={magnification} setMagnification={setMagnification} />
      </Controls>
      <CloseButton onClick={close} />
    </Heading>
  )
}
