import { Trans } from '@lingui/macro'
import React, { VFC } from 'react'

import { createRedirectUrl } from '../../services/url'
import { RedirectErrorCode } from '../../_api/signing-be/generated'
import { Button } from '../../components/Button'
import { ButtonVariant } from '../../components/Button.styled'

import { ErrorScreen } from './ErrorScreen'
import { Exit } from './OneSignError'

export interface InsufficientLevelOfAssuranceErrorProps {
  exit: Exit
}

export const InsufficientLevelOfAssuranceError: VFC<InsufficientLevelOfAssuranceErrorProps> = ({ exit }) => {
  return (
    <ErrorScreen
      buttons={
        <>
          <Button href={'https://support.oneid.bh/en/support/tickets/new'} target="_blank">
            <Trans id="insufficientLevelOfAssuranceError.contactUs">Contact us</Trans>
          </Button>
          <Button
            href={createRedirectUrl({ errorCode: RedirectErrorCode.InsufficientLevelOfAssurance, ...exit }).href}
            variant={ButtonVariant.Outline}
          >
            <Trans id="insufficientLevelOfAssuranceError.goBack">Go back to {exit.clientName}</Trans>
          </Button>
        </>
      }
      message={
        <Trans id="insufficientLevelOfAssuranceError.title">
          Identity does not meet the required level of assurance for issuing certificate
        </Trans>
      }
    />
  )
}
