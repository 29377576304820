import React from 'react'
import { useHistory } from 'react-router-dom'

import { ReactComponent as SvgLogo } from '../assets/logo.svg'

import { LogoWrapper } from './Logo.styled'

export const Logo = () => {
  const history = useHistory()
  return (
    <LogoWrapper onClick={() => history.push('/')}>
      <SvgLogo />
    </LogoWrapper>
  )
}
