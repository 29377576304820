import { Trans } from '@lingui/macro'
import React, { VFC } from 'react'

import { createRedirectUrl } from '../../services/url'
import { RedirectErrorCode } from '../../_api/signing-be/generated'
import { Button } from '../../components/Button'
import { ButtonVariant } from '../../components/Button.styled'

import { Exit } from './OneSignError'
import { ErrorScreen } from './ErrorScreen'

export interface IdentityMismatchErrorProps {
  exit: Exit
}

export const IdentityMismatchError: VFC<IdentityMismatchErrorProps> = ({ exit }) => {
  return (
    <ErrorScreen
      buttons={
        <>
          <Button href={createRedirectUrl({ errorCode: RedirectErrorCode.IdentityMismatch, ...exit }).href}>
            <Trans id="identityMismatchError.goBack">Go back to {exit.clientName}</Trans>
          </Button>
          <Button variant={ButtonVariant.Outline}>
            <Trans id="identityMismatchError.contactUs">Need help? Contact us</Trans>
          </Button>
        </>
      }
      message={<Trans id="identityMismatchError.title">Identity mismatch</Trans>}
    />
  )
}
