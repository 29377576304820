import { Trans } from '@lingui/macro'
import React, { useContext } from 'react'

import { Page } from '../components'
import { SignSteps } from '../screens'

import { useSession } from './session'

export type IdentityContext = {
  customerId: string
  wysiwysToken: string
  certificate?: string
}

const IdentityContext = React.createContext<IdentityContext>(undefined!)

export const IdentityContextProvider: React.FC = ({ children }) => {
  const {
    session: { customer },
  } = useSession()

  if (!customer) {
    return (
      <SignSteps displaySteps={false}>
        <Page loadingMessage={<Trans id="identity.waiting">Waiting for identity...</Trans>} isLoading />
      </SignSteps>
    )
  }

  return <IdentityContext.Provider value={customer}>{children}</IdentityContext.Provider>
}

export const useIdentity = () => useContext(IdentityContext)
