import { ComponentProps } from '@stitches/react'
import React, { VFC } from 'react'

import { styled } from '../styles/stitches.config'

const StyledBenefitLogo = styled('img', {
  circle: '3.5rem',
  flex: '0 0 3.5rem',
  marginInlineEnd: '0.75rem',
})

export const BenefitLogo: VFC<ComponentProps<typeof StyledBenefitLogo>> = (props) => (
  <StyledBenefitLogo src="/logo-benefit.png" {...props} />
)

export const StyledBenefitBox = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
  textAlign: 'start',
  borderWidth: '$1',
  borderStyle: 'solid',
  borderColor: '$gray200',
  borderRadius: '$M',
  padding: '1.25rem 0.75rem 1rem 0.75rem',
  position: 'relative',
  width: '100%',
  boxSizing: 'border-box',

  '& > span': {
    position: 'absolute',
    background: '$light',
    padding: '0 0.5rem',
    left: '50%',
    bottom: 'calc(100% - 0.75rem)',
    transform: 'translateX(-50%)',
    fontWeight: '$semiBold',
    textAlign: 'center',

    '@mobile': {
      fontSize: '$XS',
      bottom: 'calc(100% - 0.5rem)',
      padding: '0 0.25rem',
    },
  },

  '& h3': {
    fontSize: '$S',
    lineHeight: '1.33rem',
  },
  '& p': {
    fontSize: '0.6875rem',
    lineHeight: '0.89375rem',
  },
  '& ul': {
    marginTop: '0.5rem',
    marginInlineStart: '1rem',
    fontSize: '$XS',
    lineHeight: '0.975rem',
    color: '$gray800',
  },
})

export const BenefitBoxContent = styled('div', {
  flex: 1,
})

export const BenefitBoxFooter = styled('p', {
  fontSize: '$XS',
  color: '$gray800',
})

export const BenefitBoxFooterLink = styled('a', {
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
  fontWeight: '$bold',
  color: '$secondaryBeachBlue',
  '&:hover': {
    textDecoration: 'none',
  },
})
