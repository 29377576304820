import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'

import { InterruptionError } from '../screens/errors'
import { Page } from '../components'
import { AuthParams, ROUTES } from '../routes'
import { getWysiwysApi } from '../_api/signing-be/api'
import {
  GetWysiwysSessionResponse,
  GetWysiwysSessionResponseOutcomeEnum,
  WysiwysControllerApi,
} from '../_api/signing-be/generated'
import { SignSteps } from '../screens'
import { OneSignError } from '../screens/errors/OneSignError'
import { SessionDone } from '../screens/signing/SessionDone'

export type SessionDocument = {
  id: string
  name: string
}

export type Customer = {
  customerId: string
  wysiwysToken: string
  certificate?: string
}

export type SessionContext = {
  wysiwysApi: WysiwysControllerApi
  session: Session
  isCertificateIssuing: boolean
}

export type Session = {
  signingRequestId: string
  documents: SessionDocument[]
  redirectUri: string
  clientName: string
  outcome?: GetWysiwysSessionResponseOutcomeEnum
  customer?: Customer
}

const SessionContext = React.createContext<SessionContext>(undefined!)

const ACCESS_TOKEN_STORAGE_KEY = 'accessToken'
const IS_CERTIFICATE_ISSUING_STORAGE_KEY = 'isCertificateIssuing'
const REDIRECT_URI_STORAGE_KEY = 'redirectUri'
const CLIENT_NAME_STORAGE_KEY = 'clientName'
export const SESSION_QUERY_KEY = 'session'
export const SESSION_REF_COOKIE_NAME = 'X-SESSION-REF'

export const SessionContextProvider: React.FC = ({ children }) => {
  const match = useRouteMatch<AuthParams>({ path: ROUTES.auth, exact: true })
  const history = useHistory()
  const queryClient = useQueryClient()

  const accessToken = sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)
  const redirectUri = sessionStorage.getItem(REDIRECT_URI_STORAGE_KEY)
  const clientName = sessionStorage.getItem(CLIENT_NAME_STORAGE_KEY)
  const [wysiwysApi, setWysiwysApi] = useState<WysiwysControllerApi>(getWysiwysApi(accessToken))
  const [session, setSession] = useState<Session>()
  const [sessionError, setSessionError] = useState<{ code: string }>()

  const sessionQuery = useQuery(
    [SESSION_QUERY_KEY, accessToken],
    async () =>
      await wysiwysApi
        .getWysiwysSession()
        .then((response) => response.data)
        .catch((error) => {
          if (error?.response?.data?.code) {
            setSessionError({ code: error.response.data.code })
            return
          }

          throw error
        }),
    {
      staleTime: Infinity,
      refetchInterval: (data) => {
        if (data?.wysiwys?.customerId && !data.wysiwys?.certificate) {
          return data?.wysiwys?.certificateRefetchInterval ?? 5000 // Keep refetching for the certificate
        }

        return false
      },
      enabled: !match && !!accessToken,
    },
  )

  const initSessionMutation = useMutation(
    async (challengeCode: string) => {
      return wysiwysApi
        .initWysiwysSession({ initWysiwysSessionRequest: { signingRequestUrlId: challengeCode } })
        .then((response) => response.data)
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData<GetWysiwysSessionResponse>([SESSION_QUERY_KEY, data.accessToken], data)
        sessionStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, data.accessToken)
        sessionStorage.setItem(REDIRECT_URI_STORAGE_KEY, data.redirectUri)
        sessionStorage.setItem(CLIENT_NAME_STORAGE_KEY, data.clientName)
        Cookies.set(SESSION_REF_COOKIE_NAME, jwtDecode<{ sub: string }>(data.accessToken).sub)
        setWysiwysApi(getWysiwysApi(data.accessToken))

        if (!data.hasOneIdAccessToken) {
          history.push(ROUTES.signIn)
        } else {
          history.push(data.wysiwys?.customerId ? ROUTES.batchRoutes.root : ROUTES.certificate)
        }
      },
    },
  )

  useEffect(() => {
    if (sessionQuery?.data) {
      setSession({
        signingRequestId: sessionQuery.data.signingRequestId,
        documents: sessionQuery.data.documents,
        redirectUri: sessionQuery.data.redirectUri,
        clientName: sessionQuery.data.clientName,
        outcome: sessionQuery.data.outcome,
        customer: sessionQuery.data.wysiwys
          ? {
              customerId: sessionQuery.data.wysiwys.customerId,
              wysiwysToken: sessionQuery.data.wysiwys.accessToken,
              certificate: sessionQuery.data.wysiwys.certificate,
            }
          : undefined,
      })
    }
  }, [sessionQuery?.data])

  useEffect(() => {
    if (match && initSessionMutation.isIdle) {
      initSessionMutation.mutate(match.params.challengeCode)
    }
  }, [match, initSessionMutation])

  const certRouteMatch = useRouteMatch(ROUTES.certificate)

  useEffect(() => {
    if (certRouteMatch) {
      sessionStorage.setItem(IS_CERTIFICATE_ISSUING_STORAGE_KEY, 'true')
    }
  }, [certRouteMatch])

  if (!match && !accessToken) {
    return (
      <SignSteps displaySteps={false}>
        <InterruptionError />
      </SignSteps>
    )
  }

  if (initSessionMutation.isError) {
    return (
      <SignSteps displaySteps={false}>
        <InterruptionError />
      </SignSteps>
    )
  }

  if (sessionError) {
    return (
      <SignSteps displaySteps={false}>
        <OneSignError
          error={sessionError}
          exit={!session && redirectUri && clientName ? { redirectUri, clientName } : session}
        />
      </SignSteps>
    )
  }

  if (sessionQuery.isError) {
    return (
      <SignSteps displaySteps={false}>
        <InterruptionError retry={() => queryClient.invalidateQueries(SESSION_QUERY_KEY)} />
      </SignSteps>
    )
  }

  if (!session) {
    return <Page isLoading />
  }

  if (session.outcome) {
    return <SessionDone {...session} />
  }

  if (!accessToken) {
    throw new Error('Missing access token')
  }

  return (
    <SessionContext.Provider
      value={{
        wysiwysApi,
        session,
        isCertificateIssuing: sessionStorage.getItem(IS_CERTIFICATE_ISSUING_STORAGE_KEY) === 'true',
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = () => useContext(SessionContext)
