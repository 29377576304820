import React, { useContext, useEffect, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { config } from '../config'
import { InterruptionError } from '../screens/errors'
import { Page } from '../components'
import { BatchParams, ROUTES } from '../routes'
import { getBenefitApi } from '../_api/benefit/api'
import { BatchResponse, BenefitError, ErrorCode, WysiwysControllerApi } from '../_api/benefit/generated'
import { RedirectErrorCode } from '../_api/signing-be/generated'
import { SignSteps } from '../screens'

import { useIdentity } from './identity'
import { useSession } from './session'

export type BatchDocument = {
  id: string
  name: string
  hash: string
  pageCount: number
}

export type BatchContext = {
  batchId: string
  benefitApi: WysiwysControllerApi
  documents: BatchDocument[]
  signSessionURL: string
  certificateUploaded: boolean
}

const BatchContext = React.createContext<BatchContext>(undefined!)

const BATCH_ID_STORAGE_KEY = 'batchId'
const BATCH_QUERY_KEY = 'batch'

const getSignSessionUrl = ({ signSession, authToken }: { authToken: string; signSession: string }) => {
  const url = new URL(config.benefit.proxyPrefix + signSession, config.benefit.apiUrl)
  url.searchParams.set('authToken', authToken)

  return url.toString()
}

export const BatchContextProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const match = useRouteMatch<BatchParams>({ path: ROUTES.batchRoutes.root })
  const queryClient = useQueryClient()
  const {
    session: { documents, ...session },
  } = useSession()
  const { customerId, wysiwysToken, certificate } = useIdentity()

  const benefitApi = useMemo(() => getBenefitApi(wysiwysToken), [wysiwysToken])

  const batchId = sessionStorage.getItem(BATCH_ID_STORAGE_KEY)

  const batchQuery = useQuery(
    [BATCH_QUERY_KEY, batchId],
    () => {
      return benefitApi.getBatchMetadata({ customerId, batchId: batchId! }).then((response) => response.data)
    },
    {
      enabled: !!batchId,
      staleTime: Infinity,
    },
  )

  const createBatchMutation = useMutation(
    async (customerId: string) => {
      return benefitApi
        .createSigningBatch({
          customerId,
          documentType: 'pdf',
          createSigningBatchRequest: {
            documentIds: documents.map(({ id }) => id),
          },
        })
        .then((response) => response.data)
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData<BatchResponse>([BATCH_QUERY_KEY, data.batchId], data)
        sessionStorage.setItem(BATCH_ID_STORAGE_KEY, data.batchId)
        history.replace(ROUTES.batchRoutes.review)
      },
    },
  )

  useEffect(() => {
    if (match && !batchId && customerId && createBatchMutation.isIdle) createBatchMutation.mutate(customerId)
  }, [match, batchId, customerId, createBatchMutation])

  // As soon as certificate has been detected, upload it to the batch
  const uploadCertificateMutation = useMutation(
    async ({ customerId, batchId, certificate }: { customerId: string; batchId: string; certificate: string }) => {
      return benefitApi
        .uploadUserCertificate({
          customerId,
          batchId,
          uploadUserCertificateRequest: {
            certificate,
          },
        })
        .catch((err) => {
          if ((err as BenefitError)?.event === ErrorCode.BatchAlreadyHasCertificate) return
          if (err.response?.data?.errorCode === 'EDS-GEN-9999') return
          else throw err
        })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BATCH_QUERY_KEY)
      },
      retry: true,
    },
  )

  useEffect(() => {
    if (customerId && batchId && certificate && uploadCertificateMutation.isIdle)
      uploadCertificateMutation.mutate({ customerId, batchId, certificate })
  }, [customerId, batchId, certificate, uploadCertificateMutation])

  const exit = {
    ...session,
    errorCode: RedirectErrorCode.BatchError,
  }

  if (createBatchMutation.isError)
    return (
      <SignSteps displaySteps={false}>
        <InterruptionError retry={createBatchMutation.reset} exit={exit} />
      </SignSteps>
    )
  if (batchQuery.isIdle || batchQuery.isLoading)
    return (
      <SignSteps displaySteps={false}>
        <Page isLoading />
      </SignSteps>
    )
  if (batchQuery.isError)
    return (
      <SignSteps displaySteps={false}>
        <InterruptionError retry={() => queryClient.invalidateQueries(BATCH_QUERY_KEY)} exit={exit} />
      </SignSteps>
    )

  return (
    <BatchContext.Provider
      value={{
        batchId: batchQuery.data.batchId,
        benefitApi,
        documents: batchQuery.data.documents.map(({ documentId, pageCount, documentHash }) => ({
          id: documentId,
          name: documents.find(({ id }) => id === documentId)?.name ?? 'Ghost',
          hash: documentHash!,
          pageCount,
        })),
        signSessionURL: getSignSessionUrl({ signSession: batchQuery.data.signSession, authToken: wysiwysToken }),
        certificateUploaded:
          uploadCertificateMutation.isSuccess && batchQuery.data.documents.every((doc) => !!doc.documentHash),
      }}
    >
      {children}
    </BatchContext.Provider>
  )
}

export const useBatch = () => useContext(BatchContext)
