import { motion } from 'framer-motion'

import { styled } from '../../../styles/stitches.config'

export const Wrapper = styled(motion.div, {
  fontSize: '$S',
  color: '$light',
  padding: '0.5rem',

  '& span': {
    fontSize: '$XL',
    padding: '0 0.5rem',
  },

  '@mobile': {
    flex: '0 0 auto',
    marginLeft: 'auto',
  },
})
