// @ts-nocheck
/* eslint-disable */
/**
 * OneID Signing API
 * OneID Signing API consists of two parts
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AuthOperation = {
    Sign: 'sign',
    Login: 'login',
    IssueCertificate: 'issue_certificate'
} as const;

export type AuthOperation = typeof AuthOperation[keyof typeof AuthOperation];



