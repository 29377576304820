import { VariantProps } from '@stitches/react'
import { motion } from 'framer-motion'
import React from 'react'

import { Icon } from '../components'
import { styled } from '../styles/stitches.config'

export const Wrapper = styled(motion.div, {
  borderWidth: '$hairline',
  borderColor: '$gray150',
  borderStyle: 'solid',
  borderRadius: '$M',
  overflow: 'hidden',
  marginTop: '0.75rem',
})

export const Toggle = styled(motion.div, {
  userSelect: 'none',
  padding: '0.75rem',
  minHeight: '3.25rem',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
})

export const Content = styled('div', {
  overflow: 'hidden',
  transition: 'max-height 0.2s ease-in-out, padding 0.2s ease-in-out',
  fontSize: '$XS',

  variants: {
    expanded: {
      false: {
        maxHeight: 0,
        padding: '0 0.75rem',
      },
      true: {
        maxHeight: '20rem',
        padding: '0 0.75rem 0.75rem 0.75rem',
      },
    },
  },
})

export const Caret = styled((props: VariantProps<typeof Icon>) => <Icon {...props} name="CaretDown" />, {
  transition: 'transform 0.2s ease-in-out',
  marginInlineStart: '0.5rem',
  flex: '0 0 $sizes$iconS',

  variants: {
    expanded: {
      true: {
        transform: 'rotate(180deg)',
      },
    },
  },
})
