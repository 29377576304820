import React, { ChangeEvent, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import { StyledCheckbox } from './Checkbox.styled'
import { Icon } from './Icon'

type CheckboxProps = {
  label: string | ReactNode
  name: string
  value?: string
  error?: FieldError
  checked?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, name, checked, error, onChange }, ref) => (
    <StyledCheckbox>
      <label>
        <div>
          <input ref={ref} name={name} type="checkbox" onChange={onChange} checked={checked} />
          <div>
            <Icon name="Check" />
          </div>
        </div>
        {label}
      </label>
      {error && <span>{error.message}</span>}
    </StyledCheckbox>
  ),
)

Checkbox.displayName = 'Checkbox'
