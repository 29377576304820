import { motion } from 'framer-motion'

import { styled } from '../../../styles/stitches.config'

export const Wrapper = styled(motion.div, {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  flex: 1,
  display: 'flex',
  flexFlow: 'column nowrap',
  background: 'rgba(0, 0, 0, 0.5)',
  color: '$light',
  zIndex: 100,
  backdropFilter: 'blur(0.5rem)',

  '@desktop': {
    background: '$gray800',
    boxShadow: '0.25rem 0 0.25rem rgba(0, 0, 0, 0.25)',
  },
})

export const Content = styled('div', {
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexFlow: 'row nowrap',

  '@desktop': {
    background: '$gray800',
  },
})

export const PageWrapper = styled('ul', {
  height: '100%',
  overflow: 'auto',
  flex: 1,
  flexFlow: 'column nowrap',
  listStyle: 'none',

  '&::-webkit-scrollbar': {
    background: 'transparent',
    square: '0.5rem',
  },

  '&::-webkit-scrollbar-track': {
    background: '$gray200',
    borderRadius: '0.25rem',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '$dark',
    borderRadius: '0.25rem',
  },
})
