import React, { FC } from 'react'
import cx from 'classnames'

import { styled } from '../styles/stitches.config'
import { ReactComponent as BagSimple } from '../assets/icons/BagSimple.svg'
import { ReactComponent as Card } from '../assets/icons/Card.svg'
import { ReactComponent as CaretDown } from '../assets/icons/CaretDown.svg'
import { ReactComponent as CaretLeft } from '../assets/icons/CaretLeft.svg'
import { ReactComponent as CaretRight } from '../assets/icons/CaretRight.svg'
import { ReactComponent as Certificate } from '../assets/icons/Certificate.svg'
import { ReactComponent as ChatText } from '../assets/icons/ChatText.svg'
import { ReactComponent as Check } from '../assets/icons/Check.svg'
import { ReactComponent as Close } from '../assets/icons/Close.svg'
import { ReactComponent as DeviceMobile } from '../assets/icons/DeviceMobile.svg'
import { ReactComponent as ErrorRobot } from '../assets/icons/ErrorRobot.svg'
import { ReactComponent as Eye } from '../assets/icons/Eye.svg'
import { ReactComponent as EyeSlash } from '../assets/icons/EyeSlash.svg'
import { ReactComponent as Files } from '../assets/icons/Files.svg'
import { ReactComponent as Globe } from '../assets/icons/Globe.svg'
import { ReactComponent as HourglassHigh } from '../assets/icons/HourglassHigh.svg'
import { ReactComponent as Info } from '../assets/icons/Info.svg'
import { ReactComponent as Loading } from '../assets/icons/Loading.svg'
import { ReactComponent as Lock } from '../assets/icons/Lock.svg'
import { ReactComponent as Menu } from '../assets/icons/Menu.svg'
import { ReactComponent as User } from '../assets/icons/User.svg'
import { ReactComponent as WarningOctagon } from '../assets/icons/WarningOctagon.svg'
import { ReactComponent as Wifi } from '../assets/icons/Wifi.svg'
import { ReactComponent as SignOK } from '../assets/icons/SignOK.svg'
import { ReactComponent as SignCanceled } from '../assets/icons/SignCanceled.svg'

type IconNames = keyof typeof Icons

export interface IconProps {
  name: IconNames
  size?: 'S' | 'M' | 'L' | 'XL' | 'custom'
  onClick?: () => void
  className?: string
}

const Icons = {
  BagSimple,
  DeviceMobile,
  Globe,
  CaretDown,
  CaretLeft,
  CaretRight,
  Card,
  Certificate,
  ChatText,
  Check,
  Close,
  ErrorRobot,
  Eye,
  EyeSlash,
  Files,
  HourglassHigh,
  Info,
  Loading,
  Lock,
  Menu,
  SignCanceled,
  SignOK,
  User,
  WarningOctagon,
  Wifi,
}

const IconComponent: FC<IconProps> = ({ name, ...props }) => {
  const IconSvg = Icons[name]
  return <IconSvg {...props} />
}

const StyledIcon = styled(IconComponent, {
  variants: {
    size: {
      S: { square: '$sizes$iconS' },
      M: { square: '$sizes$iconM' },
      L: { square: '$sizes$iconL' },
      XL: { square: '$sizes$iconXL' },
      custom: { square: '$$iconSize' },
    },
  },
})

export const StyledIconComponent: FC<IconProps> = ({ size = 'M', className, ...props }) => (
  <StyledIcon size={size} className={cx('icon', className)} {...props} />
)

StyledIconComponent.toString = () => '.icon'
export const Icon = StyledIconComponent
