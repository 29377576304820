import { styled } from '../styles/stitches.config'

export const StyledCheckbox = styled('div', {
  userSelect: 'none',

  '& label': {
    fontSize: '$S',
    color: '$gray800',
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
  },

  '& label > div': {
    position: 'relative',
    display: 'inline-block',
    marginInlineEnd: '1rem',
    width: '1.25rem',
    height: '1.25rem',
  },

  '& label > div > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '$S',
    borderWidth: '$1',
    borderStyle: 'solid',
    borderColor: '$gray400',
    backgroundColor: '$light',
    square: '1.25rem',
    cursor: 'pointer',
    transition: 'background .2s ease-in-out',
  },

  '& input': {
    opacity: 0,
  },

  '& svg': {
    square: '1.25rem',
  },

  '& input + div svg': {
    display: 'none',
  },

  '& input:checked + div': {
    borderColor: '$secondaryBeachBlue',
    background: '$secondaryBeachBlue',
  },

  '& input:checked + div svg': {
    display: 'block',
  },

  '& input:checked + div path': {
    fill: '$light',
  },
})
