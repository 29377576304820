import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef, useState, VFC } from 'react'

import { OneIDDocument, Page } from '../Document'

import { Content, PageWrapper, Wrapper } from './DocumentDetail.styled'
import { DocumentDetailHeader } from './DocumentDetailHeader'
import { DocumentDetailNavigator } from './DocumentDetailNavigator'
import { DocumentDetailPage } from './DocumentDetailPage'

export interface DocumentDetailProps {
  document: OneIDDocument
  pageNumber: number
  markReadAndClose: (document: OneIDDocument, pageNumbers: number[]) => void
}

export const DocumentDetail: VFC<DocumentDetailProps> = ({ document, pageNumber, markReadAndClose }) => {
  const pageRef = useRef<HTMLLIElement | null>(null)
  const [readPages, setReadPages] = useState<number[]>([])
  const [magnification, setMagnification] = useState(1)
  const [currentPage, setCurrentPage] = useState(pageNumber)

  const debouncedSetCurrentPage = debounce(
    (pageNumber) => setReadPages((currentReadPages) => [...currentReadPages, pageNumber]),
    1500,
    { leading: false, trailing: true },
  )

  const readPage = useCallback(
    (pageNumber: number) => {
      setCurrentPage(pageNumber)
      debouncedSetCurrentPage(pageNumber)
    },
    [debouncedSetCurrentPage],
  )

  useEffect(() => {
    pageRef.current?.scrollIntoView()
  }, [pageRef])

  return (
    <Wrapper layoutId={`item-${document.id}-${pageNumber}`}>
      <DocumentDetailHeader
        document={document}
        currentPage={currentPage}
        close={() => {
          markReadAndClose(document, readPages)
        }}
        magnification={magnification}
        setMagnification={setMagnification}
      />
      <Content>
        <DocumentDetailNavigator {...document} />
        <PageWrapper>
          {document.pages.map((documentPage: Page, index: number) => (
            <DocumentDetailPage
              key={documentPage.full}
              pageNumber={index}
              page={documentPage}
              magnification={magnification}
              readPageCallback={readPage}
              ref={index === pageNumber ? pageRef : undefined}
            />
          ))}
        </PageWrapper>
      </Content>
    </Wrapper>
  )
}
