import React from 'react'

import { styled } from '../../styles/stitches.config'
import { Icon } from '../../components'

export const Title = styled('h2', {
  fontSize: '1.5rem',
  fontWeight: '$semiBold',
  maxWidth: '20rem',
  margin: '0.75rem auto',
})

const IconErrorStyled = styled(Icon, {
  '& *': {
    stroke: '$error',
  },
})

export const IconError = () => <IconErrorStyled name="ErrorRobot" size="XL" />

export const ButtonWrapper = styled('a', {
  textDecoration: 'none',
})
