import React, { FC } from 'react'
import { motion, MotionProps } from 'framer-motion'

import { styled } from '../../styles/stitches.config'

const DOCUMENT_STAGGER_DURATION = 0.25

const StyledDocuments = styled(motion.div, {
  position: 'relative',
  overflow: 'hidden',
  padding: '0 0.25rem',
})

export const Documents: FC<MotionProps> = (props) => (
  <StyledDocuments
    variants={{
      show: {
        transition: {
          staggerChildren: DOCUMENT_STAGGER_DURATION,
        },
      },
    }}
    initial="hidden"
    animate="show"
    {...props}
  />
)
