export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export const convertToBytes = (str: string): number[] => {
  const bytes = new TextEncoder().encode(str)
  return Array.from(bytes)
}

export const hexToBytes = (hex: string) => {
  if (hex.length % 2 !== 0) throw new Error('Length not even')
  const tokens = hex.match(/[0-9a-f]{2}/gi)
  if (!tokens || tokens.length !== hex.length / 2) throw new Error('Not a hex string')
  return tokens.map((t) => parseInt(t, 16))
}
