import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios'

import { config } from '../../config'

import { SigningControllerApi, WysiwysControllerApi } from './generated'

const createSigningAxios = (headers: AxiosRequestHeaders = {}): AxiosInstance => {
  return axios.create({
    baseURL: config.signing.apiUrl,
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
  })
}

export const signingApi = new SigningControllerApi(undefined, config.signing.apiUrl, createSigningAxios())

export const getWysiwysApi = (accessToken: string | null) =>
  new WysiwysControllerApi(
    undefined,
    config.signing.apiUrl,
    createSigningAxios(accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined),
  )
