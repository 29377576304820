import React, { FC } from 'react'
import { motion, MotionProps } from 'framer-motion'

import { styled } from '../../styles/stitches.config'

const DOCUMENT_STAGGER_DURATION = 0.25
const THUMBNAIL_STAGGER_DURATION = 0.1

export const StyledDocument = styled(motion.div, {
  marginBottom: '2rem',
  textAlign: 'center',

  '& h2': {
    fontSize: '$L',
    lineHeight: 1.25,
  },

  '& p': {
    fontSize: '$S',
    lineHeight: 1.5,
    color: '$gray800',
  },
})

const StyledThumbnails = styled(motion.ul, {
  listStyle: 'none',
  width: '100%',
  marginTop: '0.675rem',

  '@desktop': {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
  },

  '@mobile': {
    display: 'grid',
    gridGap: '1.25rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 5.5rem), 1fr))',
  },

  '@smallMobile': {
    display: 'grid',
    gridGap: '1.25rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 4.5rem), 1fr))',
  },
})

export const Thumbnails: FC<{ documentNumber: number } & MotionProps> = ({ documentNumber, ...props }) => (
  <StyledThumbnails
    variants={{
      show: {
        transition: {
          delayChildren: DOCUMENT_STAGGER_DURATION * documentNumber,
          staggerChildren: THUMBNAIL_STAGGER_DURATION,
        },
      },
    }}
    initial="hidden"
    animate="show"
    {...props}
  />
)

const StyledThumbnail = styled(motion.li, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 0 0.25rem rgba(0, 0, 0, 0.25)',
  position: 'relative',
  cursor: 'pointer',
  zIndex: 0,

  '& > img': {
    width: '100%',
  },

  '& svg': {
    position: 'absolute',
    right: '0.5rem',
    bottom: '0.25rem',
  },

  '@desktop': {
    width: '6rem',
    margin: '0.5rem',
  },
})

export const Thumbnail: FC<MotionProps & { onClick: () => void; active?: boolean }> = (props) => (
  <StyledThumbnail
    variants={{
      hidden: { x: 50, opacity: 0 },
      show: { x: 0, opacity: 1 },
    }}
    {...props}
  />
)
