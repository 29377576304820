import React, { VFC } from 'react'

import { Wrapper } from './PaginationIndicator.styled'

export interface PaginationIndicatorProps {
  totalPages: number
  currentPage: number
}

export const PaginationIndicator: VFC<PaginationIndicatorProps> = ({ totalPages, currentPage }) => {
  return (
    <Wrapper>
      <span>{currentPage + 1}</span>
      of
      <span>{totalPages}</span>
    </Wrapper>
  )
}
