import React, { ButtonHTMLAttributes, FC } from 'react'

import { Icon, IconProps } from './Icon'
import { ButtonTextVariant, ButtonVariant, ButtonWrapper, StyledButton } from './Button.styled'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean
  icon?: IconProps['name']
  variant?: ButtonVariant
  textSize?: ButtonTextVariant
  href?: string
  target?: string
}

export const Button: FC<ButtonProps> = ({
  icon,
  variant,
  textSize = ButtonTextVariant.M,
  children,
  href,
  target,
  type = 'button',
  ...rest
}) => {
  const button = (
    <StyledButton
      {...rest}
      type={type}
      mode={variant ? variant : !!icon ? ButtonVariant.Icon : ButtonVariant.Primary}
      textSize={textSize}
    >
      {icon && <Icon name={icon} size={variant === ButtonVariant.Icon ? 'L' : 'M'} />}
      {children}
    </StyledButton>
  )
  return href ? (
    <ButtonWrapper href={href} target={target}>
      {button}
    </ButtonWrapper>
  ) : (
    button
  )
}
