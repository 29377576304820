export enum AvailableLocale {
  En = 'en',
  Ar = 'ar',
}
export const DEFAULT_FALLBACK_LOCALE = AvailableLocale.En

export const rtlLanguages = [AvailableLocale.Ar]

export const localeNames: Record<AvailableLocale, string> = {
  en: 'English',
  ar: 'العربية',
}

export const LOCALE_STORAGE = 'lang'
