import React, { FC, ReactNode } from 'react'
import { MotionProps } from 'framer-motion'

import { Buttons, ContentContainer, Wrapper, Content, Title } from './Modal.styled'

export interface ModalProps extends MotionProps {
  title?: ReactNode
  buttons?: ReactNode
}

export const Modal: FC<ModalProps> = ({ children, title, buttons, ...props }) => {
  return (
    <Wrapper {...props}>
      <Content>
        <Title>{title}</Title>
        <ContentContainer>
          {children}
          {buttons && <Buttons>{buttons}</Buttons>}
        </ContentContainer>
      </Content>
    </Wrapper>
  )
}
