import { styled } from '../styles/stitches.config'

import { LanguageSelector } from './LanguageSelector'

export const InnerWrapper = styled('div', {
  boxSizing: 'border-box',
  flex: 1,
  display: 'flex',
  flexFlow: 'column nowrap',
  width: '100%',
  padding: '1.5rem 1.5rem 0 1.5rem',
  overflow: 'auto',

  '@desktop': {
    padding: '2.75rem',
  },
})

export const Buttons = styled('div', {
  flex: 0,
  display: 'flex',
  flexFlow: 'column nowrap',
  width: '100%',
  marginTop: '2.75rem',

  [`& > ${LanguageSelector}`]: {
    '@desktop': {
      display: 'none',
    },
  },

  '& > *': {
    marginTop: '1rem',
  },

  '@desktop': {
    maxWidth: '22.5rem',
    marginHorizontal: 'auto',
  },
})

export const ChildrenWrapper = styled('div', {
  flex: 1,
  width: '100%',
  display: 'flex',
  flexFlow: 'column nowrap',

  '@desktop': {
    maxWidth: '22.5rem',
    marginHorizontal: 'auto',
  },

  variants: {
    center: {
      true: {
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    verticalCenter: {
      true: {
        justifyContent: 'center',
      },
    },
    wide: {
      true: {
        '@desktop': {
          maxWidth: '36.25rem',
        },
      },
    },
  },
})

export const Title = styled('h2', {
  fontSize: '1.875rem',
  lineHeight: '2.25rem',
  fontWeight: '$bold',
  marginBottom: '0.75rem',
})

export const Subtitle = styled('p', {
  fontSize: '$L',
})

export const Header = styled('div', {
  marginBottom: '2.75rem',
  width: '100%',

  '@desktop': {
    textAlign: 'center',
  },
})
