import React, { ReactNode } from 'react'

import { Icon } from './Icon'
import { SpinnerPart, SpinnerText, SpinnerVisual, SpinnerShade, StyledSpinner } from './Spinner.styled'

export interface SpinnerProps {
  title?: string | ReactNode
  bare?: boolean
  size?: string
}

export const Spinner: React.FC<SpinnerProps> = ({ title, bare, size = '4rem' }) => {
  const spinner = (
    <SpinnerVisual css={{ $$size: size }}>
      <SpinnerPart animationDelay="long" bare={bare} />
      <SpinnerPart animationDelay="normal" bare={bare} />
      <SpinnerPart animationDelay="short" bare={bare} />
      <SpinnerPart bare={bare} />
      {!bare && <Icon name="Lock" size="custom" />}
    </SpinnerVisual>
  )
  return bare ? (
    spinner
  ) : (
    <SpinnerShade>
      <StyledSpinner>
        {spinner}
        {title && <SpinnerText>{title}</SpinnerText>}
      </StyledSpinner>
    </SpinnerShade>
  )
}
