import React, { useCallback, VFC } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { SESSION_QUERY_KEY, useSession } from '../../contexts/session'
import { useToggle } from '../../hooks'
import { ROUTES } from '../../routes'
import { AuthOperation, OidcCallbackSuccessMessage } from '../../_api/signing-be/generated'
import { OneIDOperation } from '../operations'

import { CreateCertificateInfo } from './CreateCertificateInfo'
import { SignStep, SignSteps } from './SignSteps'

export const Certificate: VFC = () => {
  const { wysiwysApi } = useSession()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [showInfo, toggleInfo] = useToggle(true)

  const onIssueCertificateComplete = useCallback(
    async (callbackSuccessMessage: OidcCallbackSuccessMessage) => {
      await wysiwysApi.completeIssueCertificate({ completeOneIdOperationRequest: callbackSuccessMessage })

      queryClient.invalidateQueries(SESSION_QUERY_KEY)

      history.push(ROUTES.batchRoutes.root)
    },
    [history, queryClient, wysiwysApi],
  )

  return (
    <SignSteps step={SignStep.Certificate} stepState={showInfo ? undefined : 'pending'}>
      {showInfo ? (
        <CreateCertificateInfo toggleInfo={() => toggleInfo(false)} />
      ) : (
        <OneIDOperation operation={AuthOperation.IssueCertificate} onComplete={onIssueCertificateComplete} />
      )}
    </SignSteps>
  )
}
