import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios'

import { config } from '../../config'

import { WysiwysControllerApi, WysiwysControllerApiAxiosParamCreator } from './generated'

const createBenefitAxios = (headers: AxiosRequestHeaders = {}): AxiosInstance => {
  return axios.create({
    baseURL: config.benefit.apiUrl,
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
  })
}

export const benefitApi = new WysiwysControllerApi(undefined, config.benefit.apiUrl, createBenefitAxios())

export const createPageUrl = (
  customerId: string,
  batchId: string,
  documentId: string,
  pageNumber: number,
  pageWidth: number,
  authToken: string,
) => {
  const url = new URL(
    WysiwysControllerApiAxiosParamCreator(undefined).getDocumentPage(
      {
        customerId,
        batchId,
        documentId,
        pageNumber,
        w: pageWidth,
      },
      { baseURL: config.benefit.apiUrl },
    ).url,
    config.benefit.apiUrl,
  )
  url.searchParams.set('authToken', authToken)
  return config.benefit.apiUrl + url.pathname + url.search + url.hash
}

export const getBenefitApi = (accessToken: string | null) =>
  new WysiwysControllerApi(
    undefined,
    config.benefit.apiUrl,
    createBenefitAxios(accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined),
  )
