import { i18n, Messages } from '@lingui/core'
import { fromNavigator, fromStorage, multipleDetect } from '@lingui/detect-locale'
import { I18nProvider } from '@lingui/react'
import { en as EnPlurals, ar as ArPlurals } from 'make-plural/plurals'
import React, { useEffect } from 'react'

import { messages as catalogAr } from '../assets/locales/ar/messages.js'
import { messages as catalogEn } from '../assets/locales/en/messages.js'
import { AvailableLocale, DEFAULT_FALLBACK_LOCALE, LOCALE_STORAGE, rtlLanguages } from '../constants'

i18n.loadLocaleData({
  en: { plurals: EnPlurals },
  ar: { plurals: ArPlurals },
})

const messageCatalogs: Record<AvailableLocale, Messages> = {
  en: catalogEn,
  ar: catalogAr,
}

const setDirection = (locale: AvailableLocale) => {
  const dir = rtlLanguages.includes(locale) ? 'rtl' : 'ltr'
  document.getElementsByTagName('html')[0].setAttribute('dir', dir)
}

export const initLanguage = () => {
  const detectedLocales = multipleDetect(fromStorage(LOCALE_STORAGE), fromNavigator())
  const locale =
    detectedLocales
      .map((detectedLocale) =>
        Object.values(AvailableLocale).find((availableLocale) => availableLocale === detectedLocale),
      )
      .find((availableLocale) => availableLocale !== undefined) ?? DEFAULT_FALLBACK_LOCALE

  setDirection(locale)

  i18n.load(locale, messageCatalogs[locale])
  i18n.activate(locale)
}

export const LocaleInitializer: React.FC = ({ children }) => {
  useEffect(() => initLanguage(), [])

  return (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      {children}
    </I18nProvider>
  )
}
