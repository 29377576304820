import React, { FC } from 'react'
import { MotionProps } from 'framer-motion'
import { Plural } from '@lingui/macro'

import { Icon } from '../../components'

import { StyledDocument, Thumbnails, Thumbnail } from './Document.styled'

export type Page = {
  full: string
  thumbnail: string
  isRead?: boolean
}

export type OneIDDocument = {
  id: string
  name: string
  pages: Page[]
}

export interface DocumentProps extends MotionProps {
  document: OneIDDocument
  documentNumber: number
  setOpenedPage: (document: OneIDDocument, pageNumber: number) => void
}

export const Document: FC<DocumentProps> = ({ document, documentNumber, setOpenedPage, ...rest }) => (
  <StyledDocument
    variants={{
      hidden: { opacity: 0 },
      show: { opacity: 1 },
    }}
    {...rest}
  >
    <h2>{document.name}</h2>
    <p>
      (<Plural id="document.pages.count" value={document.pages.length} one="# page" other="# pages" />)
    </p>
    <Thumbnails documentNumber={documentNumber}>
      {document.pages.map((page, index) => (
        <Thumbnail
          key={page.full}
          onClick={() => {
            setOpenedPage(document, index)
          }}
          layoutId={`item-${document.id}-${index}`}
        >
          <img src={page.thumbnail} />
          {page.isRead && <Icon name="Eye" size="S" />}
        </Thumbnail>
      ))}
    </Thumbnails>
  </StyledDocument>
)
