import React, { FC } from 'react'
import { motion, MotionProps } from 'framer-motion'

import { styled } from '../styles/stitches.config'

const StyledWrapper = styled(motion.div, {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(0.5rem)',
  zIndex: 100,
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '1.5rem',
  '@desktop': {
    justifyContent: 'center',
  },
})

export const Wrapper: FC<MotionProps> = (props) => (
  <StyledWrapper
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0, transition: { duration: 0.15 } }}
    {...props}
  />
)

export const Title = styled('h2', {
  fontSize: '$L',
  display: 'block',
  textAlign: 'center',
  paddingBottom: '2rem',
  borderBottomWidth: '$hairline',
  borderBottomStyle: 'solid',
  borderBottomColor: '$gray200',
  marginTop: '0.5rem',
  marginBottom: '2rem',
})

export const Buttons = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
  gap: '1rem',
  marginTop: '2.5rem',
})

export const ContentContainer = styled('div', {
  '& textarea': {
    width: '100%',
  },
})

const StyledContent = styled(motion.div, {
  boxSizing: 'border-box',
  maxWidth: '640px',
  width: '100%',
  padding: '1.75rem 1.25rem 1rem 1.25rem',
  background: '$light',
  borderRadius: '$XXL',
  display: 'flex',
  flexFlow: 'column nowrap',
})

export const Content: FC<MotionProps> = (props) => (
  <StyledContent
    initial={{ y: 100 }}
    animate={{ y: 0 }}
    exit={{ y: '25%', scale: 0.5, transition: { duration: 0.35 } }}
    {...props}
  />
)
