import { Trans } from '@lingui/macro'
import React, { FC } from 'react'

import { Icon, LanguageSelector, Logo, Spinner } from '../../components'

import { Circle, Content, ContentWrapper, List, ListItem, ListItemContent, SignStepsWrapper } from './SignSteps.styled'

export enum SignStep {
  Certificate = 'certificate',
  Review = 'review',
  Sign = 'sign',
}

const stepInfo = {
  [SignStep.Certificate]: {
    header: <Trans id="signSteps.certificate.header">Create certificate</Trans>,
    pendingHeader: <Trans id="signSteps.certificate.pendingHeader">Issuing certificate</Trans>,
    passedHeader: <Trans id="signSteps.certificate.passedHeader">Certificate created</Trans>,
  },
  [SignStep.Review]: {
    header: <Trans id="signSteps.review.header">Review documents</Trans>,
    pendingHeader: <Trans id="signSteps.review.header">Review documents</Trans>,
    passedHeader: <Trans id="signSteps.review.passedHeader">Documents reviewed</Trans>,
  },
  [SignStep.Sign]: {
    header: <Trans id="signSteps.sign.header">Sign documents</Trans>,
    pendingHeader: <Trans id="signSteps.sign.header">Sign documents</Trans>,
    passedHeader: <Trans id="signSteps.sign.passedHeader">Documents signed</Trans>,
  },
}

const orderedSteps = [SignStep.Certificate, SignStep.Review, SignStep.Sign]

export interface SignStepsProps {
  step?: SignStep
  stepState?: 'pending' | 'passed' | 'error'
  displaySteps?: boolean
}

export const SignSteps: FC<SignStepsProps> = ({ step, stepState, children, displaySteps = true }) => {
  const activeStepIndex = step ? orderedSteps.indexOf(step) : -1

  return (
    <SignStepsWrapper>
      <Logo />
      <ContentWrapper noSteps={!displaySteps}>
        {displaySteps && (
          <List>
            {orderedSteps.map((step, index) => {
              const state = {
                error: index === activeStepIndex && stepState === 'error',
                pending: stepState === 'pending' && index === activeStepIndex,
                active: stepState !== 'pending' && stepState !== 'passed' && index === activeStepIndex,
                passed: index < activeStepIndex || (index === activeStepIndex && stepState === 'passed'),
              }
              const info = stepInfo[step]
              return (
                info && (
                  <ListItem
                    active={state.active}
                    pending={state.pending}
                    passed={state.passed}
                    error={state.error}
                    key={index}
                  >
                    <Circle>
                      {state.error ? (
                        <Icon name={'Close'} />
                      ) : state.passed ? (
                        <Icon name="Check" />
                      ) : state.pending ? (
                        <Spinner bare size="1.5rem" />
                      ) : (
                        index + 1
                      )}
                    </Circle>
                    <ListItemContent>
                      <h3>{state.passed ? info.passedHeader : state.pending ? info.pendingHeader : info.header}</h3>
                    </ListItemContent>
                  </ListItem>
                )
              )
            })}
          </List>
        )}
        <Content>{children}</Content>
      </ContentWrapper>
      <LanguageSelector steps={displaySteps} />
    </SignStepsWrapper>
  )
}
