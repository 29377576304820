import { Plural, Trans } from '@lingui/macro'
import React, { useCallback, useEffect, useState, VFC } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { Button, Expandable, Page } from '../../components'
import { useBatch } from '../../contexts/batch'
import { useIdentity } from '../../contexts/identity'
import { SESSION_QUERY_KEY, useSession } from '../../contexts/session'
import { useToggle } from '../../hooks'
import { sign } from '../../services/signer'
import { arrayBufferToBase64 } from '../../utils'
import { AuthOperation, CryptomathicSdkCredentials, OidcCallbackSuccessMessage } from '../../_api/signing-be/generated'
import { SigningFailedError } from '../errors/SigningFailedError'
import { OneIDOperation } from '../operations'

import { IssuingCertificate } from './IssuingCertificate'
import { IconFiles, List } from './SignDocument.styled'
import { SignStep, SignSteps } from './SignSteps'

interface SigningInput {
  samlToken: string
  cryptomathicSdkCredentials: CryptomathicSdkCredentials | undefined
  disableCryptomathic: boolean | undefined
}

export const SignDocuments: VFC = () => {
  const {
    wysiwysApi,
    isCertificateIssuing,
    session: { signingRequestId, redirectUri, clientName },
  } = useSession()
  const queryClient = useQueryClient()
  const { customerId } = useIdentity()
  const batch = useBatch()
  const [showInfo, toggleInfo] = useToggle(isCertificateIssuing)

  const [signingInput, setSigningInput] = useState<SigningInput>()

  const signMutation = useMutation(
    async ({ samlToken, cryptomathicSdkCredentials, disableCryptomathic }: SigningInput) => {
      const signatures = await sign({ batch, samlToken, cryptomathicSdkCredentials, disableCryptomathic })

      await batch.benefitApi.createPadES({
        customerId,
        batchId: batch.batchId,
        createPadESRequestItem: batch.documents.map((document, index) => ({
          documentId: document.id,
          signature: arrayBufferToBase64(signatures[index]),
        })),
      })

      await wysiwysApi.completeWysiwysSession()

      queryClient.invalidateQueries(SESSION_QUERY_KEY)

      try {
        await batch.benefitApi.deleteBatch({
          customerId,
          batchId: batch.batchId,
        })
      } catch {
        // Ignore not being able to delete the batch from Benefit, since it will be automatically deleted at some point
        console.warn('Failed to delete batch')
      }
    },
  )

  const onSignComplete = useCallback(
    async (callbackSuccessMessage: OidcCallbackSuccessMessage) => {
      const {
        data: { SAML, cryptomathicSdkCredentials, disableCryptomathic },
      } = await wysiwysApi.completeSign({ completeOneIdOperationRequest: callbackSuccessMessage })

      setSigningInput({ samlToken: SAML, cryptomathicSdkCredentials, disableCryptomathic })
    },
    [wysiwysApi],
  )

  useEffect(() => {
    if (signingInput && signMutation.isIdle) signMutation.mutateAsync(signingInput)
  }, [signingInput, signMutation])

  if (!batch.certificateUploaded)
    return (
      <SignSteps step={SignStep.Certificate} stepState="pending">
        <IssuingCertificate />
      </SignSteps>
    )
  return (
    <SignSteps step={SignStep.Sign}>
      {showInfo ? (
        <Page
          buttons={
            <Button onClick={() => toggleInfo(false)}>
              <Trans id="signDocuments.button.start">Start</Trans>
            </Button>
          }
          title={<Trans id="signDocuments.title">Sign documents in your OneID account</Trans>}
        >
          <List>
            {batch.documents.map((document) => (
              <li key={document.id}>
                <IconFiles />
                {document.name}
                <span>
                  (<Plural id="document.pages.count" value={document.pageCount} one="# page" other="# pages" />)
                </span>
              </li>
            ))}
          </List>
          <Expandable title={<Trans id="signDocuments.expandable.title">What is a digital signature?</Trans>}>
            <Trans id="signDocuments.expandable.content">
              It’s a type of e-signature that complies with the strictest legal regulations and provides the highest
              level of assurance of a signer’s identity
            </Trans>
          </Expandable>
        </Page>
      ) : (
        <>
          {signMutation.isIdle && (
            <OneIDOperation operation={AuthOperation.Sign} onComplete={onSignComplete} documents={batch.documents} />
          )}
          {signMutation.isError && (
            <SigningFailedError retry={signMutation.reset} exit={{ redirectUri, signingRequestId, clientName }} />
          )}
          {signMutation.isLoading && (
            <Page loadingMessage={<Trans id="signDocuments.signing">Signing documents...</Trans>} isLoading />
          )}
        </>
      )}
    </SignSteps>
  )
}
