import { RedirectErrorCode } from '../_api/signing-be/generated'

export const ErrorDescriptions: Record<RedirectErrorCode, string> = {
  [RedirectErrorCode.BatchError]: 'Failed to create or load batch',
  [RedirectErrorCode.ConsentRejected]: 'User has rejected giving a consent',
  [RedirectErrorCode.IdentityMismatch]: "User identity wasn't stable throughout the flow",
  [RedirectErrorCode.GenericError]: 'Unspecified error',
  [RedirectErrorCode.InsufficientLevelOfAssurance]: 'User identity do not have the required level of assurance',
  [RedirectErrorCode.SigningFailed]: 'Signing failed',
}
