import React, { VFC } from 'react'

import { ErrorCode, ErrorMessage, RedirectErrorCode } from '../../_api/signing-be/generated'

import { InsufficientLevelOfAssuranceError } from './InsufficientLevelOfAssuranceError'

import { IdentityMismatchError, InterruptionError } from '.'

export type OneSignError = ErrorMessage | RedirectErrorCode | unknown

export type Exit = {
  redirectUri: string
  clientName: string
  signingRequestId?: string
}

export type OneSignErrorProps = {
  error: OneSignError
  exit?: Exit
  retry?: () => Promise<any> | any
}

const isRedirectErrorCode = (value: unknown): value is RedirectErrorCode => {
  return (Object.values(RedirectErrorCode) as string[]).includes(String(value))
}

export const OneSignError: VFC<OneSignErrorProps> = ({ error, exit, retry }) => {
  if (!exit) {
    return <InterruptionError retry={retry} />
  }

  if (isRedirectErrorCode(error)) {
    return (
      <InterruptionError
        retry={retry}
        exit={{
          ...exit,
          errorCode: error,
        }}
      />
    )
  }

  switch ((error as ErrorMessage).code) {
    case ErrorCode.IdentityMismatch:
      return <IdentityMismatchError exit={exit} />
    case ErrorCode.InsufficientLevelOfAssurance:
      return <InsufficientLevelOfAssuranceError exit={exit} />
    default:
      return (
        <InterruptionError
          retry={retry}
          exit={{
            ...exit,
            errorCode: RedirectErrorCode.GenericError,
          }}
        />
      )
  }
}
