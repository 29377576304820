import { Trans } from '@lingui/macro'
import React, { VFC } from 'react'

import { Button, BenefitBox, Icon, Page, TipBox } from '../../components'
import { ButtonTextVariant, ButtonVariant } from '../../components/Button.styled'

import { Paragraph, Title } from './IssuingCertificate.styled'

export const IssuingCertificate: VFC = () => (
  <Page
    buttons={
      <Button variant={ButtonVariant.Outline} textSize={ButtonTextVariant.S} icon="ChatText">
        <Trans id="issuingCertificate.button">Need help? Contact us</Trans>
      </Button>
    }
    center
  >
    <TipBox>
      <Trans id="issuingCertificate.tip">{`Don't close or reload the browser while your certificate is being issued.`}</Trans>
    </TipBox>
    <Icon name="Certificate" size="XL" />
    <Title>
      <Trans id="issuingCertificate.title">Issuing your certificate...</Trans>
    </Title>
    <Paragraph>
      <Trans id="issuingCertificate.paragraph">Thank you for your patience, as this can take a few minutes</Trans>
    </Paragraph>
    <BenefitBox />
  </Page>
)
