// @ts-nocheck
/* eslint-disable */
/**
 * OneID Signing API
 * OneID Signing API consists of two parts
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ErrorCode = {
    InternalError: 'INTERNAL_ERROR',
    Unauthorized: 'UNAUTHORIZED',
    CertificateNotRequested: 'CERTIFICATE_NOT_REQUESTED',
    CertificateDataMismatch: 'CERTIFICATE_DATA_MISMATCH',
    CreateSigningRequestInvalidPdf: 'CREATE_SIGNING_REQUEST_INVALID_PDF',
    CreateSigningRequestInvalidConfiguration: 'CREATE_SIGNING_REQUEST_INVALID_CONFIGURATION',
    GetSignedDocumentRequestNotFound: 'GET_SIGNED_DOCUMENT_REQUEST_NOT_FOUND',
    GetSignedDocumentDocumentNotFound: 'GET_SIGNED_DOCUMENT_DOCUMENT_NOT_FOUND',
    GetSignedDocumentDocumentNotSignedYet: 'GET_SIGNED_DOCUMENT_DOCUMENT_NOT_SIGNED_YET',
    BadData: 'BAD_DATA',
    DocumentNotFound: 'DOCUMENT_NOT_FOUND',
    DocumentAlreadySigned: 'DOCUMENT_ALREADY_SIGNED',
    InitWysiwysSigningRequestUrlNotFound: 'INIT_WYSIWYS_SIGNING_REQUEST_URL_NOT_FOUND',
    InitWysiwysSigningRequestAlreadyInitialized: 'INIT_WYSIWYS_SIGNING_REQUEST_ALREADY_INITIALIZED',
    InitWysiwysSigningRequestInvalidState: 'INIT_WYSIWYS_SIGNING_REQUEST_INVALID_STATE',
    InvalidParameters: 'INVALID_PARAMETERS',
    AccessDenied: 'ACCESS_DENIED',
    PdfFileSizeExceeded: 'PDF_FILE_SIZE_EXCEEDED',
    Conflict: 'CONFLICT',
    SigningRequestNotFound: 'SIGNING_REQUEST_NOT_FOUND',
    SigningRequestInvalidStatus: 'SIGNING_REQUEST_INVALID_STATUS',
    GetAuthUrlHashesMissing: 'GET_AUTH_URL_HASHES_MISSING',
    UserInfoNotFound: 'USER_INFO_NOT_FOUND',
    IdentityMismatch: 'IDENTITY_MISMATCH',
    SessionUserAlreadyExists: 'SESSION_USER_ALREADY_EXISTS',
    SigningRequestExpired: 'SIGNING_REQUEST_EXPIRED',
    SigningSessionNotFound: 'SIGNING_SESSION_NOT_FOUND',
    CompleteSigningMissingSignedDocuments: 'COMPLETE_SIGNING_MISSING_SIGNED_DOCUMENTS',
    InsufficientLevelOfAssurance: 'INSUFFICIENT_LEVEL_OF_ASSURANCE'
} as const;

export type ErrorCode = typeof ErrorCode[keyof typeof ErrorCode];



