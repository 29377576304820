import React from 'react'

import { styled } from '../../styles/stitches.config'
import { Icon } from '../../components'

export const List = styled('ul', {
  listStyle: 'none',
  marginBottom: '2rem',

  '& li': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '$XS',
    lineHeight: '1.25rem',
    marginBottom: '0.5rem',

    '& span': {
      color: '$gray400',
      fontSize: '$XXS',
      marginInlineStart: '0.35rem',
    },
  },
})

const IconFilesStyled = styled(Icon, {
  marginInlineEnd: '0.5rem',
})

export const IconFiles = () => <IconFilesStyled name="Files" size="M" />
