export enum ParameterNames {
  ChallengeCode = 'challengeCode',
  BatchId = 'batchId',
}

export const ROUTES = {
  home: '/',
  auth: `/auth/init/:${ParameterNames.ChallengeCode}`,
  signIn: '/signIn',
  certificate: '/certificate',
  batchRoutes: {
    root: `/batch`,
    review: `/batch/review`,
    sign: `/batch/sign`,
    complete: `/batch/complete`,
    deny: `/batch/deny`,
  },
}

export type AuthParams = {
  [ParameterNames.ChallengeCode]: string
}

export type BatchParams = {
  [ParameterNames.BatchId]: string
}
