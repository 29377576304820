import { globalCss } from './stitches.config'

export const globalStyles = globalCss({
  html: {
    fontSize: '16px',
    lineHeight: '1.5',
    height: '100%',
  },
  body: {
    height: '100%',
  },
  '*': {
    fontFamily: 'aventa, sans-serif',
    fontWeight: '@normal',
    margin: 0,
    padding: 0,
    WebkitTapHighlightColor: 'transparent',
  },
  '#root': {
    square: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
  },
})
